import 'isomorphic-fetch';

import { has, get } from 'lodash';
import { ApolloClient, HttpLink, from, ApolloLink, Observable } from '@apollo/client'; // eslint-disable-line
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'; // eslint-disable-line
import { RetryLink } from '@apollo/client/link/retry'; // eslint-disable-line
import hash from 'hash.js/lib/hash/sha/256';
import { ClientConfig } from 'client/configuration';
import { IS_NODE, ENVIRONMENT_URL } from 'client/utils/environment';
import { HTTP_REQUEST_TIMEOUT } from 'client/utils/http-status';
import { apiCache } from 'client/utils/cache-provider';
import { NoCache } from './cache';
import { handleFetch } from './graphql-client-helper';

function sha256(str) {
  return hash()
    .update(str)
    .digest('hex');
}

const DEFAULT_SERVER_SIDE_TIMEOUT_MS = 700;
const SERVER_SIDE_RETRIES = 1;
const SERVER_SIDE_ATTEMPTS = SERVER_SIDE_RETRIES + 1;
const ABSOLUTE_URL_PATTERN = /^https?:\/\//i;

function presistedQueryEnabled() {
  return process.env.PERSISTED_QUERY_ENABLED === 'true' || process.env.PERSISTED_QUERY_ENABLED === undefined;
}

// no caching at client level, will be provided at higher level
const fetchPolicy = 'no-cache';

/**
 * @param apolloError - the error that needs to be handled
 * @param query - the graph ql query
 * @param variables - the variables used in the graphql query
 * @return {Error}
 */
function transformErrorMetadata(apolloError, query, variables) {
  const error = apolloError;

  if (has(apolloError, 'networkError.response.url')) {
    error.apiUrl = apolloError.networkError.response.url;
    error.networkError.response = 'REMOVED-IN-ERROR-HANDLING';
    error.networkError.bodyText = 'REMOVED-IN-ERROR-HANDLING';
  }
  if (has(apolloError, 'networkError.type')) {
    if (apolloError.networkError.type.match('timeout')) {
      // networkError.type is typically 'request-timeout' or 'body-timeout'
      error.status = HTTP_REQUEST_TIMEOUT;
    }
  }
  error.queryVars = variables;
  return error;
}

export const getErrorStatusCode = error => get(error, 'networkError.statusCode', error.status);

/**
 * Returns true if GraphQL timeout is disabled, false by default
 * Provides developers an option to disable GraphQL timeout in local environment in case of slow connection
 *
 * @returns {Boolean}
 */
function isGraphqlTimeoutDisabled() {
  return ClientConfig.get('graphqlTimeoutDisabled', false);
}

function isVenomCacheEnabled() {
  // todo apiCacheEnabled should instead be a feature flag called venomCache
  return ClientConfig.get('apiCacheEnabled', false);
}

function getCacheKey(operation) {
  const { graphUrl } = operation.getContext();
  if (presistedQueryEnabled()) {
    return `graphUrl: ${graphUrl}, persistedQueryHash:${
      operation.extensions.persistedQuery.sha256Hash
    }, variables:${JSON.stringify(operation.variables)}`;
  }
  return `graphUrl: ${graphUrl}, query: ${operation.query.loc.source.body}, variables: ${JSON.stringify(
    operation.variables
  )}`;
}

const fulfillRequestFromVenomCache = new ApolloLink((operation, forward) => {
  if (!isVenomCacheEnabled()) {
    return forward(operation);
  }
  const { useVenomCache } = operation.getContext();
  if (!useVenomCache) {
    return forward(operation);
  }
  const cacheKey = getCacheKey(operation);
  const response = apiCache.get(cacheKey);
  if (!response) {
    return forward(operation);
  }
  global.logger.debug(
    `\x1b[0m[ \x1b[35mCACHE\x1b[0m ]\x1b[33m retrieved gql:${
      operation.extensions.persistedQuery.sha256Hash
    }\x1b[0m from cache`
  );

  return new Observable(observer => {
    observer.next(response);
    observer.complete();
  });
});

const saveResponseToVenomCache = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    if (!isVenomCacheEnabled()) {
      return response;
    }
    const context = operation.getContext();
    const { useVenomCache, response: httpResponse } = context;
    if (useVenomCache) {
      const matches = httpResponse.headers?.get?.('cache-control')?.match(/max-age=(\d+)/);
      const maxAge = matches ? parseInt(matches[1], 10) : 0;
      if (maxAge > 0) {
        const cacheKey = getCacheKey(operation);
        apiCache.set(cacheKey, response, maxAge * 1000);
      }
    }
    return response;
  })
);

function createApolloClient(uri) {
  // https://github.com/bitinn/node-fetch#options
  const fetchOptions = {
    timeout: isGraphqlTimeoutDisabled()
      ? 0 // req/res timeout in ms, it resets on redirect. 0 to disable (OS limit applies).
      : ClientConfig.get('graphqlDefaultTimeoutMs', DEFAULT_SERVER_SIDE_TIMEOUT_MS),
    method: 'GET',
  };

  const linkArr = [
    // Retry logic sits as first in the chain to handle any connection errors
    new RetryLink({
      // https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-retry
      delay: {
        initial: 0, // The number of milliseconds to wait before attempting the first retry.
        max: 0, // The maximum number of milliseconds that the link should wait for any retry.
        jitter: false, // Whether delays between attempts should be randomized.
      },
      attempts: {
        max: SERVER_SIDE_ATTEMPTS, // The max number of times to try a single operation before giving up.
      },
    }),

    // conditional logic to add the persisted query link as second in the chain, before HttpLink
    ...(presistedQueryEnabled() ? [createPersistedQueryLink({ sha256, useGETForHashedQueries: true })] : []),
    fulfillRequestFromVenomCache,
    saveResponseToVenomCache,
    // the final part of the chain is the call out to GraphQL
    new HttpLink({
      uri,
      // https://www.apollographql.com/docs/link/links/http.html#options
      // fetchOptions: any overrides of the fetch options argument to pass to the fetch call
      fetchOptions,
      // https://www.apollographql.com/docs/react/api/link/apollo-link-http/#customizing-fetch
      // creating a simple fetch pass-through so we can log the graphql request for local dev
      fetch: handleFetch,
    }),
  ];

  return new ApolloClient({
    // Using `from` for additive link chain composition
    // https://www.apollographql.com/docs/react/api/link/introduction/#additive-composition
    link: from(linkArr),
    cache: new NoCache(),
  });
}

class GraphQLClient {
  constructor(graphUrl) {
    this.graphUrl = graphUrl;
    this.client = null;
  }

  query(query, variables = {}, options = {}) {
    const graphUrl = this.graphUrl();
    if (!this.client) {
      this.client = createApolloClient(graphUrl);
    }

    return this.client
      .query({
        context: { graphUrl, ...options },
        query,
        variables,
        fetchPolicy,
      })
      .then(response => response.data)
      .catch(apolloError => {
        throw transformErrorMetadata(apolloError, query, variables);
      });
  }
}

export const EdmundsGraphQLFederation = new GraphQLClient(() => {
  const graphQLUrl = ClientConfig.get('edmundsGraphQLFederationUrl');

  if (IS_NODE && !ABSOLUTE_URL_PATTERN.test(graphQLUrl)) {
    return `${ENVIRONMENT_URL}${graphQLUrl}`;
  }

  return graphQLUrl;
});
