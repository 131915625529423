import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const shoppingRoutes = [
  /**
   * Example: https://www.edmunds.com/honda/civic/shopping
   */
  {
    path: `/:make(${makes.join('|')})/:model/shopping`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "new_model_shopping" */ '../pages/shopping/shopping-definition')
    ),
    chunkName: 'new_model_shopping',
    methods: 'get,post',
    enableLooseEtag: true,
  },
];
