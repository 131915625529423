import { LRUCache } from 'lru-cache';
import { recordServerMetric } from 'client/utils/monitoring';

/**
 * Simple in memory cache
 * Currently this class is using https://www.npmjs.com/package/lru-cache under the hood
 * essentially this is meant to be a thin wrapper to lru-cache providing the same API
 */
export class Cache {
  /**
   * @type LRUCache Private LRU Cache
   */
  #itemsStorage;
  /**
   * @param {LRUCache.Options} options
   */
  constructor(options) {
    // internal cache entries storage
    this.#itemsStorage = new LRUCache(options);
  }

  #updateMetric() {
    recordServerMetric('cacheSize', this.size);
  }
  /**
   * Check if a key is in the cache, without deleting it for being stale.
   * @param {String} key - Key in cache
   * @returns {Boolean}
   */
  has(key) {
    return this.#itemsStorage.has(key);
  }
  /**
   * Returns the cache value associated to the key, or undefined if there is none or expired.
   * @param {String} key - Key in cache
   * @returns {*}
   */
  get(key) {
    return this.#itemsStorage.get(key);
  }

  /**
   * Sets the value for the key in the cache for the defined ttl (forever if ttl not specified)
   * @param {String} key
   * @param {*} value
   * @param {Number} [ttl] specified in ms
   */
  set(key, value, ttl = undefined) {
    const options = ttl ? { ttl } : {};
    this.#itemsStorage.set(key, value, options);

    this.#updateMetric(); // todo make this call with 1% probability
  }
  /**
   * Deletes the cache value by the key
   * @param {String} key - Key in cache
   * @returns {void}
   */
  delete(key) {
    this.#itemsStorage.delete(key);

    this.#updateMetric();
  }
  /**
   * Removes all key/value pairs from the cache
   */
  clear() {
    this.#itemsStorage.clear();

    this.#updateMetric();
  }
  /**
   * Return an array of the keys in the cache.
   * @returns {Iterable<String>}
   */
  keys() {
    return this.#itemsStorage.keys();
  }
  /**
   * Return an array of the values in the cache.
   * @returns {Iterable<*>}
   */
  values() {
    return this.#itemsStorage.values();
  }
  /**
   * Returns the number of key/value pairs in the cache
   * @returns {number}
   */
  get size() {
    return this.#itemsStorage.size;
  }
}
