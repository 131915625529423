import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

export const routes = {
  path: [
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/features-specs`,
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/st-:styleId/features-specs`,
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/:submodel/features-specs`,
    `/:make(${makes.join('|')})/:model/:year(\\d{4})/:submodel/st-:styleId/features-specs`,
  ],
  exact: true,
  page: getLoadablePage((params, store) => {
    const plat4078Recipe = ExperimentUtil.getForcedOrAssignedRecipeName({
      state: store.getState(),
      campaignName: 'plat-4078',
      defaultVal: 'ctrl',
    });
    /* istanbul ignore next */
    switch (plat4078Recipe) {
      case 'chal1':
        return import(/* webpackChunkName: "new_model_core_feature_specs-plat-4078-chal1" */ '../pages/features-specs/features-specs-definition.plat-4078-chal1');
      case 'chal2':
        return import(/* webpackChunkName: "new_model_core_feature_specs-plat-4078-chal2" */ '../pages/features-specs/features-specs-definition.plat-4078-chal2');
      case 'chal3':
        return import(/* webpackChunkName: "new_model_core_feature_specs-plat-4078-chal3" */ '../pages/features-specs/features-specs-definition.plat-4078-chal3');
      case 'chal4':
        return import(/* webpackChunkName: "new_model_core_feature_specs-plat-4078-chal4" */ '../pages/features-specs/features-specs-definition.plat-4078-chal4');
      case 'chal5':
        return import(/* webpackChunkName: "new_model_core_feature_specs-plat-4078-chal5" */ '../pages/features-specs/features-specs-definition.plat-4078-chal5');
      default:
        return import(/* webpackChunkName: "new_model_core_feature_specs" */ '../pages/features-specs/features-specs-definition');
    }
  }),
  chunkName: 'new_model_core_feature_specs',
};
