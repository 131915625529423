import {
  CHANGE_FORCED_AB_PARAMETER,
  CHANGE_FORCED_AX_DIMENSIONS,
  COMPUTED_AB_PARAMETERS,
  REGISTER_EXPERIMENT_ON_PAGE,
  SET_EXPOSURE_RECORD,
} from './constants';

/**
 * Defines successfully computed AB-testing campaign parameters action payload
 *
 * @param  {Object} data campaign parameters computed data.
 * @return {Object}      Successfully computed AB-testing campaign parameters action payload
 */
export function computedAbCampaignParameters(data) {
  return {
    type: COMPUTED_AB_PARAMETERS,
    data,
  };
}

/**
 * Defines change 'forced' field of parameter object action payload.
 *
 * @param  {String}  campaignName campaign name.
 * @param  {String}  paramName parameter name.
 * @param  {Object}  paramValue parameter value to force.
 * @return {Object}  change 'forced' field of parameter object action payload.
 */
export function changeForcedAbParameter(campaignName, paramName, paramValue) {
  return {
    type: CHANGE_FORCED_AB_PARAMETER,
    campaignName,
    paramName,
    paramValue,
  };
}

/**
 * Defines change 'forced' field of parameter object action payload.
 *
 * @param  {String}  campaignName campaign name.
 * @param  {String}  paramName parameter name.
 * @param  {Object}  dimensions parameter dimensions to force.
 * @return {Object}  change 'forced' field of parameter object action payload.
 */
export function changeForcedAxDimensions(campaignName, dimensions) {
  return {
    type: CHANGE_FORCED_AX_DIMENSIONS,
    campaignName,
    dimensions,
  };
}

/**
 * Fires experiment activated event and dispatches register experiment on page action.
 *
 * @param  {String} name  experiment name.
 * @param  {boolean} isPublished  flag that indicates whether experiment is published.
 * @param  {boolean} isActive  flag that indicates whether experiment is active - bucketed to a recipe.
 * @param  {boolean} isEligible  flag that indicates whether experiment is eligible by filters.
 * @return {Object}  Register a name of the experiment that is present on the current page action payload.
 */
export function registerExperimentOnPage(name, isPublished, isEligible, isActive, isPageExperiment) {
  return {
    type: REGISTER_EXPERIMENT_ON_PAGE,
    name,
    isPublished,
    isActive,
    isEligible,
    isPageExperiment,
  };
}

export function setExposureRecord(data) {
  return {
    type: SET_EXPOSURE_RECORD,
    data,
  };
}
