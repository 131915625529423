import { isNode } from 'client/utils/environment';

/**
 * @param {string} key context key
 * @param {*} value value
 */
export function setContext(key, value) {
  if (isNode() && global.httpContext) {
    // global.httpContext is for server side only (imported from express-http-context)
    global.httpContext.set(key, value);
  }
  // todo add support for context on client side
}
