// ads-refresh
//

/**
 * ADS_REFRESH action name
 * @type {String}
 */
export const ADS_REFRESH = 'ADS_REFRESH';

/**
 * ADUNITS_REFRESH action name
 * @type {String}
 */
export const ADUNITS_REFRESH = 'ADUNITS_REFRESH';

/**
 * ADS_CHANGE_ZONE action name
 * @type {String}
 */
export const ADS_CHANGE_ZONE = 'ADS_CHANGE_ZONE';

export const PAGE_ADS_CUSTOM_TARGETING = 'PAGE_ADS_CUSTOM_TARGETING';

// car-buying
//
export const ADD_CREDIT_SCORE_DATA = 'ADD_CREDIT_SCORE_DATA';

// financing
//
export const ADD_FINANCING_DATA = 'ADD_FINANCING_DATA';

// critical-css
//

/**
 * SET_CRITICAL_CSS action name
 * @type {String}
 */
export const SET_CRITICAL_CSS = 'SET_CRITICAL_CSS';

// dealer
//

/**
 * UPDATE_DEALER action name
 * @type {String}
 */
export const UPDATE_DEALER = 'UPDATE_DEALER';

// experiment
//

/**
 * Successfully received AB-testing Venom campaigns data action name
 * @type {String}
 */
export const RECEIVED_AB_CAMPAIGNS = 'RECEIVED_AB_CAMPAIGNS';

/**
 * Successfully computed AB-testing campaign parameters action name.
 * @type {String}
 */
export const COMPUTED_AB_PARAMETERS = 'COMPUTED_AB_PARAMETERS';

export const COMPUTE_AND_FORCE_DONE = 'COMPUTE_AND_FORCE_DONE';

/**
 * Change 'forced' field of parameter object action name.
 * @type {String}
 */
export const CHANGE_FORCED_AB_PARAMETER = 'CHANGE_FORCED_AB_PARAMETER';

/**
 * Register a name of the experiment that is present on the current page action name.
 * @type {String}
 */
export const REGISTER_EXPERIMENT_ON_PAGE = 'REGISTER_EXPERIMENT_ON_PAGE';

/**
 * Change forced ax dimensions.
 * @type {String}
 */
export const CHANGE_FORCED_AX_DIMENSIONS = 'CHANGE_FORCED_AX_DIMENSIONS';

/**
 * UPDATE_EXT_TAG_ATTRIBUTE action name
 * @type {String}
 */
export const UPDATE_EXT_TAG_ATTRIBUTE = 'UPDATE_EXT_TAG_ATTRIBUTE';

// facet-state
//
export const UPDATE_FACET_STATE = 'UPDATE_FACET_STATE';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';

// feature-flags
//

/**
 * UPDATE_FEATURE_FLAGS action name
 * @type {String}
 */
export const UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS';

// form
//

/**
 * FORM_SUBMITTED action name
 * @type {String}
 */
export const FORM_SUBMITTED = 'FORM_SUBMITTED';

// legacy-page
//

/**
 * LEGACY_PAGE_UPDATE action name
 * @type {String}
 */
export const LEGACY_PAGE_UPDATE = 'LEGACY_PAGE_UPDATE';

// location
//
export const LOCATION_CHANGE = 'LOCATION_CHANGE';

// url context
//
export const UPDATE_URL_CONTEXT = 'UPDATE_URL_CONTEXT';

// location context
//
export const UPDATE_LOCATION_CONTEXT = 'UPDATE_LOCATION_CONTEXT';

// page
//

/**
 * PAGE_PRELOAD action name
 * @type {String}
 */
export const PAGE_PRELOAD = 'PAGE_PRELOAD';

/**
 * PAGE_START_LOADING action name
 * @type {String}
 */

export const PAGE_START_LOADING = 'PAGE_START_LOADING';

/**
 * PAGE_META action name
 * @type {String}
 */
export const PAGE_META = 'PAGE_META';

/**
 * SESSION_ENTRY action name
 * @type {String}
 */
export const SESSION_ENTRY = 'SESSION_ENTRY';

/**
 * PAGE_LOAD action name
 * @type {String}
 */
export const PAGE_LOAD = 'PAGE_LOAD';

/**
 * PAGE_UNLOAD action name
 * @type {String}
 */
export const PAGE_UNLOAD = 'PAGE_UNLOAD';

/**
 * PAGE_STATUS_UPDATE action name
 * @type {String}
 */
export const PAGE_STATUS_UPDATE = 'PAGE_STATUS_UPDATE';

/**
 * WIDGET_VIEW action name
 * @type {String}
 */
export const WIDGET_VIEW = 'WIDGET_VIEW';

// profile
//
export const SAVE_CURRENT_SCROLL_PROPS = 'SAVE_CURRENT_SCROLL_PROPS';
export const CLEAR_CURRENT_SCROLL_PROPS = 'CLEAR_CURRENT_SCROLL_PROPS';
export const TOGGLE_PROFILE_SCREEN = 'TOGGLE_PROFILE_SCREEN';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN = 'SIGN_IN';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SIGN_IN_FIREBASE = 'SIGN_IN_FIREBASE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const CLEAR_PROFILE_DATA = 'CLEAR_PROFILE_DATA';

// responsive-breakpoint
//

/**
 * BREAKPOINT_UPDATE action name
 * @type {String}
 */
export const BREAKPOINT_UPDATE = 'BREAKPOINT_UPDATE';

// vehicle
//
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';

// version
//
export const UPDATE_VERSION = 'UPDATE_VERSION';

// visitor
//
export const UPDATE_SESSION = 'UPDATE_SESSION';

// redirect
//
export const REDIRECT = 'REDIRECT';

export const FORCE_DISABLE_LOOSE_ETAG = 'FORCE_DISABLE_LOOSE_ETAG';

// exposed experiments
//
export const SET_EXPOSURE_RECORD = 'SET_EXPOSURE_RECORD';

// venom test action
//
export const SET_VENOM_TEST_HEADER = 'SET_VENOM_TEST_HEADER';
