import { compact } from 'lodash';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { stripNonNumeric } from 'client/utils/string-utils';
import { validationHelper } from 'client/site-modules/shared/components/validation/validation-helper/validation-helper';

/**
 * Builds URL for photos API
 * Adds sortby params to keep consistency between environments
 */
export function formatPhotosURL({ make, model, year, params = {}, groupBy = false }) {
  const query = objectToQueryString({
    ...params,
    sortby: compact([params.sortby, 'display_order:asc,id:asc,photoprovider:asc']).join(','),
  });

  return `/media/v2/${make}/${model}/${year}/photos/${groupBy ? 'groupby' : ''}?${query}`;
}

/**
 * Builds url for get all TMV bands API
 * @param {string} styleId
 * @param {string | number} zipCode
 * @param {string | number} [mileage] - optional
 * @param {string} [colorId] - optional
 * @param {string} [optionIds] - optional
 * @return {string}
 */
export function formatAllTmvBandsUrl({ styleId, zipCode, mileage, colorId, optionIds }) {
  const formattedMileage = typeof mileage === 'string' ? stripNonNumeric(mileage) : mileage;
  const validatedZipCode = validationHelper.validateZipCode({ fieldValue: zipCode }) ? zipCode : '';

  const queryParams = objectToQueryString({
    styleid: styleId,
    ...(validatedZipCode ? { zipcode: zipCode } : {}),
    mileage: formattedMileage,
    colorid: colorId,
    optionids: optionIds,
  });

  return `/v2/usedtmv/getalltmvbands?${queryParams}&typical=false&view=full&priceband=false`;
}
