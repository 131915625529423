import { makes, TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { getQuery } from 'client/utils/location';

export const configuratorLeadFormRoutes = [
  /**
   * Route for configurator lead form, e.g.
   * https://www.edmunds.com/lead-form/configurator.html?make=acura&model=rdx&year=2018
   */
  {
    path: '/lead-form/configurator.html',
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "configurator-lead-form" */ '../pages/configurator-lead-form-definition'),
      (match, location) => ({
        ...getQuery(location),
        pathname: location.pathname,
      })
    ),
    chunkName: 'configurator-lead-form',
    methods: 'get,post',
    enableLooseEtag: true,
  },
  /**
   * https://www.edmunds.com/make/model/year/configurator.html
   * https://www.edmunds.com/acura/rdx/2018/configurator.html
   */
  {
    path: `/:make(${makes.join('|')})/:model/:year(\\d{4})/configurator.html`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "configurator-lead-form" */ '../pages/configurator-lead-form-definition'),
      (match, location) => ({
        ...getQuery(location),
        pathname: location.pathname,
        ...match.params,
        make: TWO_WORDS_MAKE_NAMES_MAP[match.params.make] || match.params.make,
      })
    ),
    chunkName: 'configurator-lead-form',
    methods: 'get,post',
    enableLooseEtag: true,
  },
];
