import { get, has, isEmpty, sortBy, find } from 'lodash';
import dateFormat from 'dateformat';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { BRANDS, BRAND_TYPES } from 'site-modules/multi-offer/constants/brands';
import { MULTI_OFFER_RESULTS, OFFER_DETAILS } from 'site-modules/multi-offer/constants/render-types';
import { REDEMPTION_STATES } from 'site-modules/multi-offer/constants/redemption-states';
import { getCurrentDate } from 'site-modules/shared/utils/date-utils';
import { CTA_TEXTS } from 'site-modules/multi-offer/components/multi-offer-results/offer-details-card/constants';

/**
 * Given the offer details vehicle object, it will return the year make model to be displayed.
 * @param {Object} vehicle
 * @returns {String} If empty, it will return empty string.
 */
export const getYearMakeModelDisplay = (vehicle = {}) => {
  if (isEmpty(vehicle)) return '';
  const { year, make, model } = vehicle;

  return `${year} ${make} ${model}`;
};

/**
 * Given the offer details vehicle object, it will return the make model to be displayed.
 * @param {Object} vehicle
 * @returns {String} If empty, it will return empty string.
 */
export const getMakeModelDisplay = (vehicle = {}) => {
  if (isEmpty(vehicle)) return '';
  const { make, model } = vehicle;

  return `${make} ${model}`;
};

/**
 * Given the offer details object, it will return the mileage to be displayed.
 * @param {Object} vehicle
 * @param {String} append - Text to append
 * @returns {String}
 */
export const getMileageDisplay = (vehicle = {}, append = '') => {
  if (isEmpty(vehicle)) return '';
  const { mileage } = vehicle;

  return `${numberWithCommas(mileage)}${append}`;
};

const EXPIRES_DISPLAY_DATE_MASK = 'm/d/yy';
/**
 * Given the offer details object, it will return the formatted expired date to be displayed.
 * @param {Object} offerDetails
 *    @param {String} expiresDisplayDate
 * @returns {String}
 */
export const getExpiresDisplayDate = ({ expiresDisplayDate }) => {
  try {
    const isUTCFormat = new Date(expiresDisplayDate).getUTCHours() === 0;
    return dateFormat(expiresDisplayDate, EXPIRES_DISPLAY_DATE_MASK, isUTCFormat);
  } catch (e) {
    // we should NOT expect a value that can be parsed as a date from the "expiresDisplayDate" field.
    return expiresDisplayDate;
  }
};

/**
 * Determines if the brand exist in BRAND_TYPES, if not it will be consider as "generic".
 * @param {Object} offerDetails
 *    @param {String} brand
 * @returns {Boolean}
 */
export const isGenericBrand = ({ brand }) => brand === BRANDS.GENERIC || !has(BRAND_TYPES, brand);

/**
 * Determines if the offer is expired.
 * @param {Object} offerDetails
 *    @param {String} expiresDisplayDate
 * @returns {Boolean}
 */
export const isExpiredOffer = offerDetails => {
  let expirationDate;
  const expiresDateUtc = get(offerDetails, 'expiresDateUtc');

  if (expiresDateUtc) {
    expirationDate = new Date(expiresDateUtc);
  } else {
    expirationDate = new Date(get(offerDetails, 'expiresDisplayDate'));
    expirationDate.setDate(expirationDate.getDate() + 1);
  }

  return getCurrentDate() > expirationDate;
};

export const isEstimatedOffer = offerDetails => {
  const estimate = get(offerDetails, 'estimate');
  const valuation = get(offerDetails, 'valuation');

  return !valuation && !!estimate;
};

/**
 * Returns a NEW array with offers sorted according to the 'offerDisplayNumber' field.
 *
 * @param {Array} offers offers to display on the page; this array is NOT modified.
 * @returns {Array} offers in the order for displaying on the page.
 */
export const sortOffersForDisplay = offers => sortBy(offers, ['offerDisplayNumber']);

/**
 * Returns brand name from BRAND_TYPES, if it doesn't exist then it will return it's "brand" because it's consider "generic".
 *
 * If the offer is obscured (see EMO-1257), then it returns the "Brand 2"/"Brand 3" string, unless the
 * hideObscuredBrand param is specified as "false".
 *
 * IMPORTANT NOTE: if you use this function for tracking/reporting, then most likely you need to pass
 * the hideObscuredBrand parameter as "false".
 *
 * @param {Object} offerDetails
 *    @param {String} brand
 *    @param {Boolean} isObscured
 *    @param {Number} offerDisplayNumber
 * @param {Boolean} hideObscuredBrand flag, that indicates whether a placeholder (like "Brand 2") should be returned
 * instead of the real brand, when the passed offerDetails denotes an obscured offer. Optional, default is "true".
 * @returns {String}
 */
export const getBrandName = ({ brand, isObscured, offerDisplayNumber }, hideObscuredBrand = true) => {
  if (isObscured && hideObscuredBrand) {
    return `Brand ${offerDisplayNumber}`;
  }

  return get(BRAND_TYPES, [brand, 'name'], brand);
};

const findBrandDisclaimer = offerDetails => {
  const genericDisclaimer = BRAND_TYPES[BRANDS.GENERIC].disclaimer;
  const isExpired = isExpiredOffer(offerDetails);
  const isEstimated = isEstimatedOffer(offerDetails);

  const { isObscured, brand } = offerDetails;

  const brandDisclaimer = get(BRAND_TYPES, [brand, 'disclaimer'], genericDisclaimer);
  if (brandDisclaimer && isExpired) {
    return isEstimated
      ? `We're sorry, we're unable to display values for outdated estimated offers for security reasons. Please refresh to see your car's latest value.`
      : "We're sorry, we're unable to display values for expired offers for security reasons. Please refresh your offer to see your car's value.";
  }

  if (isObscured) {
    return get(BRAND_TYPES, [brand, 'disclaimerObscured'], genericDisclaimer);
  }

  return brandDisclaimer;
};

/**
 * Returns disclaimer from either
 *  1. brand disclaimer from BRAND_TYPES
 *  2. expired disclaimer only if disclaimer exists from BRAND_TYPES and is expired offer
 * @param {Object} offerDetails
 *    @param {String} brand
 *    @param {Boolean} isObscured
 * @returns {String}
 */
export const getBrandDisclaimer = offerDetails => {
  const disclaimer = findBrandDisclaimer(offerDetails);
  if (typeof disclaimer === 'function') {
    return disclaimer(offerDetails);
  }
  return disclaimer;
};

/**
 * Returns brand class name if it brand exists in BRAND_TYPES, if it doesn't exist then it will return "generic" class name.
 * @param {Object} offerDetails
 * @returns {String}
 */
export const getBrandClassName = offerDetails => (isGenericBrand(offerDetails) ? BRANDS.GENERIC : offerDetails.brand);

/**
 * Returns the button color for specific brand.
 * @param {Object} offerDetails
 * @returns {String}
 */
export const getButtonColor = offerDetails => (isGenericBrand(offerDetails) ? 'primary-b' : offerDetails.brand);

/**
 * Returns expired offer copy.
 * @param {Object} offerDetails
 *    @param {String} expiresDisplayDate
 * @returns {Object}
 */
export const getExpiredOfferCopy = offerDetails => ({
  header: "It's time to refresh your offer",
  listItems: [
    `This offer expired on ${getExpiresDisplayDate(offerDetails)}`,
    'Refresh your offer to get the updated value',
  ],
});

export const getExpiredEstimatedOfferCopy = offerDetails => ({
  header: 'It’s time to refresh your car’s value',
  listItems: [
    `This estimated offer is out of date as of ${getExpiresDisplayDate(offerDetails)}`,
    'Refresh to get an updated offer or estimate',
  ],
});

/**
 * Determines whether it is an ODP (Offer Details Page) EXPIRED render case.
 * @param {String} renderType
 * @returns {Boolean}
 */
export const isOdpExpiredOffer = renderType => OFFER_DETAILS.EXPIRED === renderType;

export const isOdpEstimatedOffer = renderType => OFFER_DETAILS.ESTIMATED === renderType;

export const isOdpEstimatedExpiredOffer = renderType => OFFER_DETAILS.ESTIMATED_EXPIRED === renderType;

export const isOdpAnyTypeEstimatedOffer = renderType =>
  isOdpEstimatedOffer(renderType) || isOdpEstimatedExpiredOffer(renderType);

/**
 * Determines whether it is pending offers.
 * @param {String} renderType
 * @returns {Boolean}
 */
export const isPendingOffers = renderType => MULTI_OFFER_RESULTS.PENDING === renderType;

/**
 * Determines whether SOME but NOT all offers have expired on MOR page.
 * @param {String} renderType
 * @returns {Boolean}
 */
export const isSomeOffersExpired = renderType => MULTI_OFFER_RESULTS.DEFAULT_HAS_EXPIRED === renderType;

/**
 * Determines whether ALL offers have expired on MOR page.
 * @param {String} renderType
 * @returns {Boolean}
 */
export const isAllOffersExpired = renderType => MULTI_OFFER_RESULTS.DEFAULT_ALL_EXPIRED === renderType;

/**
 * Determines if an expired offer is present on MOR page by checking renderType.
 *
 * @param {String} renderType
 * @returns {Boolean} true if renderType is MULTI_OFFER_RESULTS.DEFAULT_ALL_EXPIRED or MULTI_OFFER_RESULTS.DEFAULT_HAS_EXPIRED
 */
export const hasAnyExpiredOffer = renderType => isSomeOffersExpired(renderType) || isAllOffersExpired(renderType);

/**
 * Determines whether to show the "refresh offer" button by checking renderType
 * @param {String} renderType
 * @returns {Boolean}
 */
export const isShowRefreshOffersButton = renderType => isAllOffersExpired(renderType);

export const isPostPiiRedemptionFinalized = redemptionState =>
  redemptionState === REDEMPTION_STATES.POST_PII_REDEMPTION_FINALIZED;

export const isPostPiiRedemptionPending = redemptionState =>
  redemptionState === REDEMPTION_STATES.POST_PII_REDEMPTION_PENDING;

export const isHighestOffer = ({ offerNumber }) => offerNumber === 1;

/**
 * Returns offer with the highest valuation.
 *
 * @param {Array} offers array with offers.
 * @returns {Object} offer object.
 */
export const getHighestOffer = offers => find(offers, isHighestOffer) || get(offers, 0, {});

export const getBrandOffer = (offers, brand, partner) =>
  partner ? find(offers, { brand, partner }) : find(offers, { brand });

/**
 * Returns offer code to display in UI.
 *
 * @param {String} code offer code.
 * @param {String} brand offer brand.
 * @returns {String} offer code to display in UI.
 */
export const getDisplayOfferCode = ({ code, brand }) => (brand === BRANDS.KMX ? code : '');

/**
 * Returns active (non-expired) offers.
 *
 * @param {Array} offers
 * @returns {Array}
 */
export const getActiveOffers = offers => offers.filter(offer => !isExpiredOffer(offer));

/**
 * Given the offer details object, it will return the string ordinal number of this offer, using the 'offerNumber' field.
 *
 * @param {Object} offerDetails
 *    @param {Number} offerNumber
 * @returns {String}
 */
export const getOfferOrdinalNumber = ({ offerNumber }) => {
  switch (offerNumber) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    default:
      // Assume, that theoretically there can be more than 3 offers, but not more than 20.
      return `${offerNumber}th`;
  }
};

/**
 * Placeholder text that is used for obscured offers.
 *
 * @param {Object} offerDetails offer object.
 * @returns {String} placeholder text.
 */
export const getOfferPlaceholder = offerDetails => `${getOfferOrdinalNumber(offerDetails)} Offer`;

export const getOfferProvider = offerDetails => {
  const { isObscured } = offerDetails;
  return isObscured ? 'the offer provider' : getBrandName(offerDetails);
};

export const canScheduleAppt = (offerDetails, hasCarmaxStore) => {
  const { brand } = offerDetails;
  // we cannot schedule an appointment with CarMax if we don't have a CarMax store.
  return brand === BRANDS.KMX && hasCarmaxStore;
};

/**
 * Returns if the given offers are the same.
 *
 * @param {Object} offerDetails1 offer 1.
 * @param {Object} offerDetails2 offer 2.
 * @returns {Boolean}
 */
export const areOffersEqual = (offerDetails1, offerDetails2) =>
  ['partner', 'source', 'brand'].every(key => get(offerDetails1, key) === get(offerDetails2, key));

/**
 * Determines whether or not the text-shadow-contrast-high class should be applied
 * @param {Object} offerDetails offer object
 * @returns {Boolean}
 */
export const isContrastRequired = offerDetails => {
  const { brand } = offerDetails;
  return brand !== BRANDS.PEDDLE;
};

export const getCardBtnDefaultText = ({ redemptionState, brand }) => {
  if (isPostPiiRedemptionFinalized(redemptionState)) {
    return CTA_TEXTS.CONTINUE;
  }
  if (brand === BRANDS.AUTONATION) {
    return CTA_TEXTS.ACTIVATE_OFFER;
  }
  return CTA_TEXTS.VIEW_OFFER_DETAILS;
};
