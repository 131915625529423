import get from 'lodash/get';
import { EventToolbox } from 'client/utils/event-toolbox';
import { PAGE_EVENTS } from 'client/constants/page-events';
import { PUB_STATES } from 'client/constants/pub-states';
import { DEFAULT_SEO_CONTEXT } from 'site-modules/shared/pages/seo';
import { isLocationChanged } from 'client/utils/location';

const SIMPLIFY_COLOR_NAME_REGEX = /^"|\|.*|"$/g;

/**
 * Replaces RGB info from color name and not necessary double quotes.
 * @param {String} sourceString A color name, for example: '"Nougat Brown leather|93,69,57"'.
 * @return {String} The simplified color name, like 'Nougat Brown leather'.
 */
export function simplifyColorName(sourceString = '') {
  return sourceString.replace(SIMPLIFY_COLOR_NAME_REGEX, '');
}

/**
 * Builds page context for page_enter "action" event
 *
 * @param  {Object} page  Page metadata
 * @param  {Object} state App redux state
 * @return {Object}       Page context
 */
export function getPageContext(page = {}, state = {}) {
  const {
    pageContext = {},
    visitor = {},
    seo = {
      headContent: {},
    },
    url = {},
  } = state;
  const vehicle = pageContext.vehicle || {};
  const legacy = pageContext.legacy || {};
  const region = visitor.location || {};
  const now = Date.now();
  const urlContext = url.context || {};

  return {
    platform_type: 'venom',

    request_url: window.location.href,
    referral_url: window.document.referrer,
    ua: window.navigator.userAgent,
    title: seo.headContent.title || DEFAULT_SEO_CONTEXT.title,
    screen_resolution: [window.screen.height, window.screen.width].join('x'),
    browser_width: window.innerWidth,
    geoloc_enabled: 'geolocation' in window,

    page_name: legacy.pageName || page.name,
    page_category: legacy.categoryName || page.category,
    page_template: page.name,
    page_uuid: now,
    publication_state: (vehicle.publicationState || PUB_STATES.NEW).toLowerCase(),
    make_id: vehicle.make && vehicle.make.id,
    make_nicename: vehicle.make && vehicle.make.niceName,
    make_adtargetid: vehicle.make && vehicle.make.adTargetId,
    model_year_id: vehicle.modelYear && vehicle.modelYear.id,
    model_nicename: vehicle.model && vehicle.model.niceName,
    model_adtargetid: vehicle.model && vehicle.model.adTargetId,
    model_name: vehicle.model && vehicle.model.name,
    submodel_id: vehicle.subModel && vehicle.subModel.id,
    submodel_nicename: vehicle.subModel && vehicle.subModel.niceName,
    submodel_adtargetid: vehicle.subModel && vehicle.subModel.adTargetId,
    model_link_code: vehicle.model && vehicle.model.modelLinkCode,
    year: vehicle.modelYear && vehicle.modelYear.year,
    type: vehicle.type && vehicle.type.niceName,
    type_list: vehicle.typeList,
    style_id: vehicle.style && vehicle.style.id,
    price: vehicle.displayPrice,
    sale_price: vehicle.salePrice,
    vin: vehicle.vin,
    trim: vehicle.trim,
    extColor: simplifyColorName(vehicle.exteriorColor),
    color: simplifyColorName(vehicle.interiorColor),

    visitor_id: visitor.id,
    capi_ext_id: visitor.capiExtId,
    session_id: visitor.session,
    ias_data: visitor.iasData,
    user_zip_code: region.zipCode,
    ip_zip_code: region.ipZipCode,
    dma: region.dma || '',
    url_pattern: urlContext.urlPattern,
    usprivacy: visitor.privacy && visitor.privacy.uspString,
    donotsell: visitor.privacy && visitor.privacy.doNotSell,

    ts: now,
    tsp: now,
    timestamp: now,
  };
}

export function handlePageLoad(event, getState) {
  const state = getState();
  const page = event.detail || {};
  const previousLocation = state.pageContext.previousLocation;

  if (
    event.type !== PAGE_EVENTS.PAGE_UPDATE && // Page update without URL change
    (!previousLocation || !isLocationChanged(previousLocation, page.location)) // Do nothing if location is not changed
  )
    return;

  const context = getPageContext(page, state);
  const partnerId = get(state, 'pageContext.page.partnerId') || get(state, 'pageContext.dealer.rooftopId');

  EventToolbox.fireTrackAction({
    ...context,
    partner_id: partnerId,
    event: 'page_enter',
    event_type: 'page_enter',
    event_data: {
      action_cause: 'route_change',
      spa_load_time_ms: page.spaPageLoadTime,
    },
  });
}

export const PageEngagementHandler = {
  /**
   * Setups "pageLoad"" event listener
   *
   * @param  {Object} getState Returns App redux store state
   * @return {void}
   */
  init({ getState }) {
    EventToolbox.on(`${PAGE_EVENTS.PAGE_LOAD} ${PAGE_EVENTS.PAGE_UPDATE}`, event => handlePageLoad(event, getState));
  },
};
