import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

const PATHS = {
  PL_CREATE_LISTING: '/sell-car/private/:step(create-listing)',
  PL_VEHICLE_DETAILS: '/sell-car/private/create-listing/:step(vehicle-details)',
  PL_LIST_PRICE: '/sell-car/private/create-listing/:step(set-list-price)',
  PL_PHOTO_UPLOADER: '/sell-car/private/create-listing/:step(upload-photos)',
  PL_SELLER_INFO: '/sell-car/private/create-listing/:step(seller-info)',
  PL_PREVIEW: '/sell-car/private/create-listing/:step(preview)',
};

export const routes = [
  {
    path: PATHS.PL_CREATE_LISTING,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "private_listing_create_listing" */ '../pages/steps/create-listing')
    ),
    chunkName: 'private_listing_create_listing',
  },
  {
    path: PATHS.PL_VEHICLE_DETAILS,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "private_listing_vehicle_details" */ '../pages/steps/vehicle-details')
    ),
    chunkName: 'private_listing_vehicle_details',
  },
  {
    path: PATHS.PL_LIST_PRICE,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "private_listing_set_list_price" */ '../pages/steps/list-price')
    ),
    chunkName: 'private_listing_set_list_price',
  },
  {
    path: PATHS.PL_PHOTO_UPLOADER,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "private_listing_upload_photos" */ '../pages/steps/photo-uploader')
    ),
    chunkName: 'private_listing_upload_photos',
  },
  {
    path: PATHS.PL_SELLER_INFO,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "private_listing_seller_info" */ '../pages/steps/seller-info')
    ),
    chunkName: 'private_listing_seller_info',
  },
  {
    path: PATHS.PL_PREVIEW,
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "private_listing_preview" */ '../pages/steps/preview')),
    chunkName: 'private_listing_preview',
  },
];
