import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const facebookLandingRoutes = [
  {
    /**
     * Route for "facebook" landing page
     * https://www.edmunds.com/facebook/
     */
    path: ['/facebook', '/social'],
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "facebook-landing" */ '../pages/facebook-landing/facebook-landing-definition')
    ),
    chunkName: 'facebook-landing',
    enableLooseEtag: true,
  },
];
