import { SET_EXPOSURE_RECORD } from 'client/actions/constants';

export function exposureRecordReducer(state = '', action = {}) {
  switch (action.type) {
    case SET_EXPOSURE_RECORD:
      return action.data;
    default:
      return state;
  }
}
