import { get, sortBy, isEmpty, flatMap, mapValues, map, maxBy, compact } from 'lodash';
import { TrackingConstant } from 'client/tracking/constant';
import { getQuery } from 'client/utils/location';
import { BRANDS } from 'site-modules/multi-offer/constants/brands';
import { MULTI_OFFER_RESULTS, OFFER_DETAILS } from 'site-modules/multi-offer/constants/render-types';
import {
  isExpiredOffer,
  isEstimatedOffer,
  isPostPiiRedemptionFinalized,
  areOffersEqual,
  getBrandOffer,
} from 'site-modules/multi-offer/utils/offers';
import { PARTNERS } from 'site-modules/shared/constants/multi-offer/partners';
import { DISPLAY_SUPPORTED_PARTNERS } from 'site-modules/shared/constants/multi-offer/multi-offer-config';
import { REDEMPTION_STATES } from 'site-modules/multi-offer/constants/redemption-states';

const REDACTED_VALUATION = null;

export const getSolicitedPartners = partnersToSolicit => {
  if (!Array.isArray(partnersToSolicit) || !partnersToSolicit.length) {
    return [];
  }
  return DISPLAY_SUPPORTED_PARTNERS.filter(partner => partnersToSolicit.includes(partner.partnerToSolicit));
};

/**
 * @param {Object} partnerData
 * @param {Array} solicitedPartners
 * @returns {Object} subcopy of partnerData with only solicited partners key/values
 */
export const filterPartnerData = (partnerData, solicitedPartners) => {
  if (isEmpty(solicitedPartners)) {
    return {};
  }
  const filteredPartners = Object.keys(partnerData).filter(partnerLabel =>
    solicitedPartners.some(partner => partner.partnerKey === partnerLabel)
  );
  return filteredPartners.reduce(
    (result, partnerLabel) => ({
      ...result,
      [partnerLabel]: partnerData[partnerLabel],
    }),
    {}
  );
};

export const sortQuestionsByDisplayOrder = questions => sortBy(questions, ['displayOrder']);

export const sortOffersByValuation = (o1, o2) => get(o2, 'valuation') - get(o1, 'valuation');

export const sortOffersByValuationAndExpiredStatus = (o1, o2) => {
  const o1IsExpired = isExpiredOffer(o1);
  const o2IsExpired = isExpiredOffer(o2);
  const o1Valuation = get(o1, 'valuation');
  const o2Valuation = get(o2, 'valuation');
  switch (true) {
    case o1IsExpired && !o2IsExpired:
      return 1;
    case !o1IsExpired && o2IsExpired:
      return -1;
    case !o1IsExpired && !o2IsExpired:
      return o2Valuation - o1Valuation;
    default:
      return 0;
  }
};

/**
 * Filters offers down to those that have been accepted and (valuation above 0 OR valuation is redacted)
 * @param {Object} offer offer details
 * @param {String} offer.status accepted or declined status of offer
 * @param {Number} offer.valuation offer amount
 * @returns {Boolean} whether the offer is valid to be displayed to the user
 */
export const acceptedOfferFilter = ({ status, valuation, redactedAt }) => {
  const isRedactedOffer = valuation === REDACTED_VALUATION && redactedAt;
  return status === 'accepted' && (isRedactedOffer || valuation > 0);
};

/**
 * Filters offers down to those that have been declined
 * @param {Object} offer offer details
 * @param {String} offer.status accepted or declined status of offer
 * @returns {Boolean} whether the offer is declined
 */
export const declinedOfferFilter = ({ status }) => status === 'declined';

/**
 * Returns number of expired offers
 * @param {Array} offers array of offer objects
 * @returns {Number} count of expired offers
 */
export const getNumberOfExpiredOffers = offers => offers.reduce((a, b) => a + (isExpiredOffer(b) ? 1 : 0), 0);

/**
 * This constructs the offer object to include the brand and source.
 * Brand and source will fallback to the "partner" key if it does not exist.
 * @param {Object} offer offer details
 * @param {String} partner - Partner Key
 * @param {Object} partnerObject the object that contains all the partner related fields; currently a MODS record has
 * a top-level "partners" object, it contains a "partner object" for each partner.
 * @returns {Object}
 */
export const constructOfferObject = (offer, partner, partnerObject) => ({
  ...offer,
  partner,
  brand: get(offer, 'brand', partner),
  source: get(offer, 'source', partner),
  redemptionInfoResponse: partnerObject?.redemptionInfoResponse,
});

/**
 * Given the solicitedResponse value, it determines whether it is in a "okay/can proceed" state (either successful status or "undefined").
 *
 * Important:
 *  - "solicitedResponse === undefined" is REQUIRED.
 *    - In this case, "undefined >= 200 && undefined < 300" returns "false", but we need "undefined" to pass this check for our business logic.
 *    - Between the solicit request being made on the VAC and the user being redirected to the MOR page, there are a few seconds when we won't
 *      have a response from our partner yet and "solicitedResponse === undefined", so we have to account for that.
 *    - See https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/-/merge_requests/18931#note_773720 for full details.
 * @param {Number|undefined} solicitedResponse
 * @returns {Boolean}
 */
export const isSolicitedResponseOkay = solicitedResponse =>
  (solicitedResponse >= 200 && solicitedResponse < 300) || solicitedResponse === undefined;

/**
 * Returns whether the page is in the "offers pending" state (i.e. partner offers are not finalized yet).
 *
 * @param {Object} partnerData partner data, includes:
 *    @param {Array} offers partner offers.
 *    @param {Number|null} solicitedResponse the response code from the solicitation request to the partner.
 * @param {Object} location the "location" object as it is stored in the PageModel luckdragon model.
 * @returns {Boolean} whether the page is in the "offers pending" state.
 */
export const areOffersPending = ({ offers, solicitedResponse }, location) => {
  // If there is "solicitStatus" query param in the page URL, then it means that the 'POST /solicit-more-offers' call failed.
  const { solicitStatus } = getQuery(location);

  return offers === undefined && isSolicitedResponseOkay(solicitedResponse) && !solicitStatus;
};

export const generateSubactionPageloadTracking = (subActionName, value) => ({
  event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
  event_data: {
    action_name: TrackingConstant.ACTION_RECEIVE_EPO,
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
    subaction_name: subActionName,
    value,
  },
});

const getSolicitedOffers = modsRecord => {
  const partnerData = get(modsRecord, 'partners', {});
  const solicitedPartners = getSolicitedPartners(get(modsRecord, 'partnersToSolicit'));
  const filteredPartnerData = filterPartnerData(partnerData, solicitedPartners);
  return flatMap(Object.values(filteredPartnerData), partnerObject => get(partnerObject, 'offers', []));
};

/**
 * Returns the tracking map for the offer count. In the use case where there
 * is an expired offer from `finalOffers` it will return empty (no tracking).
 * @param {Array} finalOffers - offers that are displayed (or used) on the page.
 * @param {Object} modsRecord
 * @param {Array} solicitedOffers (optional) - ALL offers that are solicited
 * @returns {Object}
 */
export const getOfferCountTrackingMap = (finalOffers, modsRecord, solicitedOffers) => {
  const trackingMap = {};

  if (getNumberOfExpiredOffers(finalOffers) > 0) {
    // Return empty right away
    return trackingMap;
  }

  const kmxOffer = get(modsRecord, ['partners', BRANDS.KMX, 'offers', 0]);
  const allOffers = compact([kmxOffer, ...(solicitedOffers || getSolicitedOffers(modsRecord))]);

  trackingMap[TrackingConstant.TOTAL_OFFER_COUNT] = generateSubactionPageloadTracking(
    TrackingConstant.TOTAL_OFFER_COUNT,
    allOffers.length
  );
  trackingMap[TrackingConstant.ACCEPTED_OFFER_COUNT] = generateSubactionPageloadTracking(
    TrackingConstant.ACCEPTED_OFFER_COUNT,
    allOffers.filter(acceptedOfferFilter).length
  );
  trackingMap[TrackingConstant.DECLINED_OFFER_COUNT] = generateSubactionPageloadTracking(
    TrackingConstant.DECLINED_OFFER_COUNT,
    allOffers.filter(declinedOfferFilter).length
  );

  return trackingMap;
};

/**
 * Returns the tracking map based off the offers that are displayed.
 * @param {Array} offers
 * @returns {Object}
 */
export const getOfferDisplayCountTrackingMap = offers => {
  const trackingMap = {};

  const numberOfExpiredOffers = getNumberOfExpiredOffers(offers);

  trackingMap[TrackingConstant.ACTIVE_OFFER_DISPLAY_COUNT] = generateSubactionPageloadTracking(
    TrackingConstant.ACTIVE_OFFER_DISPLAY_COUNT,
    offers.length - numberOfExpiredOffers
  );
  trackingMap[TrackingConstant.EXPIRED_OFFER_DISPLAY_COUNT] = generateSubactionPageloadTracking(
    TrackingConstant.EXPIRED_OFFER_DISPLAY_COUNT,
    numberOfExpiredOffers
  );
  trackingMap[TrackingConstant.OFFER_DISPLAY_COUNT] = generateSubactionPageloadTracking(
    TrackingConstant.OFFER_DISPLAY_COUNT,
    offers.length
  );

  return trackingMap;
};

/**
 * @param {Array} solicitedPartners
 * @returns {Object} tracking event object for solicited partners
 */
export const getSolicitedPartnersTrackingMap = solicitedPartners => ({
  [TrackingConstant.PARTNERS_TO_SOLICIT]: generateSubactionPageloadTracking(
    TrackingConstant.PARTNERS_TO_SOLICIT,
    solicitedPartners.length ? map(solicitedPartners, 'partnerToSolicit').join(',') : null
  ),
});

export const getMultiOfferTrackingMap = isMultiOffer => ({
  [TrackingConstant.MULTI_OFFER]: generateSubactionPageloadTracking(TrackingConstant.MULTI_OFFER, isMultiOffer),
});

export const getOffersPendingTrackingMap = () => ({
  [TrackingConstant.OFFERS_PENDING]: generateSubactionPageloadTracking(TrackingConstant.OFFERS_PENDING, undefined),
});

/**
 * Returns the tracking map for "subaction_name: carwiser_solicited_response_status_code".
 * @param {Object} partners "partners" data from MODS record.
 * @returns {Object} tracking event object for Carwiser solicited partner response
 */
export const getCarwiserSolicitedPartnerResponseStatusCodeTrackingMap = partners => ({
  [TrackingConstant.CARWISER_SOLICITED_RESPONSE_STATUS_CODE]: generateSubactionPageloadTracking(
    TrackingConstant.CARWISER_SOLICITED_RESPONSE_STATUS_CODE,
    get(partners, [PARTNERS.CARWISER, 'solicitedResponse'])
  ),
});

/**
 * Returns the tracking map for "subaction_name: ab_tests".
 * @param {Object} "seller" data from MODS record.
 * @returns {Object} tracking event object for seller ab_test record data
 */
export const getSellerAbTestTrackingMap = seller => {
  const abTests = get(seller, ['abTestBucketing'], []);
  return {
    [TrackingConstant.MODS_AB_TEST_BUCKETING]: generateSubactionPageloadTracking(
      TrackingConstant.MODS_AB_TEST_BUCKETING,
      abTests.length ? abTests.join(',') : null
    ),
  };
};

export const getDeclineReasonTrackingMap = (offers, brand, partner) => {
  const brandOffer = getBrandOffer(offers, brand, partner);
  const status = brandOffer?.status;
  const declineReason = brandOffer?.declineReason;

  if (status === 'declined' && declineReason)
    return {
      [`${TrackingConstant.SUBACTION_DECLINE_REASON_VALUE}_${brand}`]: generateSubactionPageloadTracking(
        `${TrackingConstant.SUBACTION_DECLINE_REASON_VALUE}_${brand}`,
        declineReason
      ),
    };

  return {};
};

export const buildOfferBrandPositionKey = brand => `offer_brand_position_${brand}`;

/**
 * Returns the tracking map based off the offer brand.
 * @param {Array} finalOffers offers data that are filtered.
 * @returns {Object} Returns an object with the key as subaction_name and the value of the tracking data.
 */
export const getOfferBrandsTrackingMap = finalOffers => {
  const trackingMap = {};
  const hasExpiredOffers = getNumberOfExpiredOffers(finalOffers) > 0;

  finalOffers.forEach((offer, index) => {
    const { brand } = offer;

    if (isExpiredOffer(offer)) {
      trackingMap[`expired_offer_brand_${brand}`] = generateSubactionPageloadTracking(
        TrackingConstant.EXPIRED_OFFER_BRAND,
        brand
      );
    } else {
      const key = hasExpiredOffers ? `offer_brand_${brand}` : buildOfferBrandPositionKey(brand); // Key needs to be unique
      const subactionName = hasExpiredOffers
        ? TrackingConstant.OFFER_BRAND
        : `${TrackingConstant.OFFER_BRAND}_${index + 1}`;
      trackingMap[key] = generateSubactionPageloadTracking(subactionName, brand);
    }
  });

  return trackingMap;
};

/**
 * The reasons why there are...
 *  1. two DEFAULT expired render types: "at least one expired" and "all expired" has a slightly different UI.
 *  2. two carmax only render types: "only-carmax" "only-carmax-expired" has a slightly different UI.
 *
 * See EMO-358 (DEFAULT offers) and EMO-300 (Carmax only offers) for full details.
 * @param {Boolean} offersArePending
 * @param {Array} offers
 * @returns {String} renderType
 */
export const getMorPageRenderType = (offersArePending, offers = []) => {
  const numberOfExpiredOffers = getNumberOfExpiredOffers(offers);
  const hasExpiredOffers = numberOfExpiredOffers > 0;

  switch (true) {
    case offersArePending: // If any offers are still pending
      return MULTI_OFFER_RESULTS.PENDING;
    case hasExpiredOffers: // If all or some offers are expired
      return numberOfExpiredOffers === offers.length
        ? MULTI_OFFER_RESULTS.DEFAULT_ALL_EXPIRED
        : MULTI_OFFER_RESULTS.DEFAULT_HAS_EXPIRED;
    default:
      return MULTI_OFFER_RESULTS.DEFAULT;
  }
};

export const getOdpPageRenderType = offer => {
  const expired = isExpiredOffer(offer);
  const estimated = isEstimatedOffer(offer);

  switch (true) {
    case estimated && expired:
      return OFFER_DETAILS.ESTIMATED_EXPIRED;
    case estimated:
      return OFFER_DETAILS.ESTIMATED;
    case expired:
      return OFFER_DETAILS.EXPIRED;
    default:
      return OFFER_DETAILS.DEFAULT;
  }
};

export const getOfferRedemptionState = (offer, redemptionStateByPartner) => {
  const { offerOfInterest, partner } = offer;

  if (!offerOfInterest) {
    return REDEMPTION_STATES.PRE_PII;
  }

  return redemptionStateByPartner[partner];
};

const enhanceOfferObjects = ({ offers = [], redemptionStateByPartner }) => {
  let highestOffer;
  // Check if NO offer was redacted.
  const areAllValuationsPresent = offers.every(({ valuation }) => !!valuation);
  if (areAllValuationsPresent) {
    highestOffer = maxBy(offers, 'valuation');
  }

  return offers.map((offer, index) => {
    const redemptionState = getOfferRedemptionState(offer, redemptionStateByPartner);
    const isHighest = !!highestOffer && areOffersEqual(offer, highestOffer);
    const offerNumber = index + 1;
    return {
      ...offer,
      offerNumber,
      offerDisplayNumber: offerNumber,
      isObscured: !isPostPiiRedemptionFinalized(redemptionState) && !isHighest,
      redemptionState,
    };
  });
};

/**
 * Returns multi offer results object for the page (MOR page).
 * If there is error then it returns object empty case object.
 * @param {Object} data - data to help with returning the multi offer results object.
 * @returns {Object}
 */
export const getMultiOfferResultsObject = ({
  offers,
  vehicle,
  pageloadTrackingBySubactionName,
  offersArePending,
  isError = false,
  createdAt,
  updatedAt,
  seller,
  redemptionStateByPartner,
}) => {
  if (isError) {
    return {
      offers: [],
      vehicle: {},
      renderType: MULTI_OFFER_RESULTS.DEFAULT,
    };
  }

  return {
    offers: enhanceOfferObjects({ offers, redemptionStateByPartner }),
    vehicle,
    pageloadTrackingBySubactionName,
    renderType: getMorPageRenderType(offersArePending, offers),
    createdAt,
    updatedAt,
    seller,
  };
};

/**
 * Returns object for offer details page (ODP).
 * If there is error then it returns empty case object.
 * @param {Object} data - data to help with returning the offer details page object.
 * @returns {Object}
 */
export const getOfferDetailsPageObject = ({
  offer,
  vehicle,
  pageloadTrackingBySubactionName,
  isError = false,
  seller,
  isMultiOffer,
}) => {
  if (isError) {
    return {
      offer: {},
      vehicle: {},
      renderType: OFFER_DETAILS.DEFAULT,
    };
  }

  return {
    offer,
    vehicle,
    pageloadTrackingBySubactionName,
    renderType: getOdpPageRenderType(offer),
    seller,
    isMultiOffer,
  };
};

/**
 * Determine the state of the partner in relation to requesting the redemption info (which includes submitting PII).
 *
 * @param {Object} partnerObject the object that contains all the partner related fields; currently a MODS record has
 * a top-level "partners" object, it contains a "partner object" for each partner.
 * @returns {String} partner redemption state.
 */
export const getPartnerRedemptionState = partnerObject => {
  const { requestRedemptionInfoCalled, redemptionInfoFinalizedAt, redemptionInfoResponse } = partnerObject;

  const isRedemptionInfoFailed =
    redemptionInfoResponse === null || redemptionInfoResponse < 200 || redemptionInfoResponse >= 300; // false when redemptionInfoResponse === undefined, which is expected.

  if (!!redemptionInfoFinalizedAt || isRedemptionInfoFailed) {
    return REDEMPTION_STATES.POST_PII_REDEMPTION_FINALIZED;
  }
  // Since we're here, that means the redemption info is NOT finalized.
  if (requestRedemptionInfoCalled) {
    return REDEMPTION_STATES.POST_PII_REDEMPTION_PENDING;
  }
  // Since we're here, that means the redemption info is NOT finalized AND is NOT pending.
  return REDEMPTION_STATES.PRE_PII;
};

/**
 * Returns an object where a key is a partner and the value is the redemption state of this partner.
 *
 * @param partnerData the top-level "partners" object of the MODS record.
 * @returns {Object}
 */
export const getRedemptionStateByPartner = partnerData => mapValues(partnerData, getPartnerRedemptionState);

/**
 * If the passed array contains two EchoPark offers, one from the direct integration and another from Carwiser, then
 * the Carwiser offer is excluded from the array.
 *
 * @param {Array} offers
 * @returns {Array} processed offers.
 */
export const processEchoparkOffers = offers => {
  const hasEchoparkDirect = offers.some(({ partner }) => partner === PARTNERS.ECHOPARK);
  if (!hasEchoparkDirect) {
    return offers;
  }
  return offers.filter(({ partner, brand }) => brand !== BRANDS.ECHOPARK || partner !== PARTNERS.CARWISER);
};
