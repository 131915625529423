import { getBedLengthValue } from 'site-modules/shared/utils/features-format';

const TIRE_SIZE_REGEXP = /^p|tires$/gi;

export function tireSizeFormatter(value = '') {
  if (value === null || value === '') {
    return value;
  }

  return value
    .replace(TIRE_SIZE_REGEXP, '')
    .trim()
    .toUpperCase();
}

export const warrantyFeatureMap = {
  camelName: 'warranty',
  name: 'Warranty',
  values: [
    {
      camelName: 'basic',
      name: 'Basic',
    },
    {
      camelName: 'drivetrain',
      name: 'Drivetrain',
    },
    {
      camelName: 'freeMaintenance',
      name: 'Roadside Assistance',
    },
  ],
};

export const interiorFeatureMap = {
  camelName: 'interiorFeatures',
  name: 'Features',
  values: [
    {
      camelName: 'bluetooth',
      name: 'Bluetooth',
    },
    {
      camelName: 'navigation',
      name: 'Navigation',
    },
    {
      camelName: 'satelliteRadio',
      name: 'Satellite radio',
    },
    {
      camelName: 'keylessIgnition',
      name: 'Keyless Ignition',
    },
    {
      camelName: 'cruiseControl',
      name: 'Cruise control',
    },
    {
      camelName: 'adaptiveCruiseControl',
      name: 'Adaptive cruise control',
    },
    {
      camelName: 'parkingAssistance',
      name: 'Parking assistance',
    },
    {
      camelName: 'upholstery',
      name: 'Upholstery',
    },
    {
      camelName: 'heatedSeats',
      name: 'Heated seats',
    },
    {
      camelName: 'cooledVentilatedSeats',
      name: 'Cooled/Ventilated seats',
    },
    {
      camelName: 'foldingRearSeats',
      name: 'Folding rear seats',
    },
    {
      camelName: 'sunroof',
      name: 'Sunroof',
      path: 'exteriorFeatures.sunroof',
      categoryCamelName: 'exteriorFeatures',
    },
  ],
};

export const specificationsFeatureMap = {
  camelName: 'specifications',
  name: 'Specifications',
  values: [
    {
      camelName: 'driveTrain',
      name: 'Drivetrain',
    },
    {
      camelName: 'enginePower',
      name: 'Engine power',
    },
    {
      camelName: 'engineTorque',
      name: 'Engine torque',
    },
    {
      camelName: 'engineDisplacement',
      name: 'Engine displacement',
    },
    {
      camelName: 'engineCylinderCount',
      name: 'Engine configuration',
    },
    {
      camelName: 'transmission',
      name: 'Transmission',
    },
    {
      camelName: 'tireSize',
      name: 'Tire size',
      formatter: tireSizeFormatter,
      path: 'exteriorFeatures.tireSize',
      categoryCamelName: 'exteriorFeatures',
    },
    {
      camelName: 'wheelTireSize',
      name: 'Wheel size',
      path: 'exteriorFeatures.wheelTireSize',
      categoryCamelName: 'exteriorFeatures',
    },
    {
      camelName: 'wheelType',
      name: 'Wheel type',
      path: 'exteriorFeatures.wheelType',
      categoryCamelName: 'exteriorFeatures',
    },
    {
      camelName: 'runFlatTires',
      name: 'Run flat tires',
      path: 'exteriorFeatures.runFlatTires',
      categoryCamelName: 'exteriorFeatures',
    },
  ],
};

export const fuelEconomyFeatureMap = {
  camelName: 'fuelEconomy',
  name: 'Fuel economy',
  values: [
    {
      camelName: 'fuelEconomyStandard',
      name: 'City/Hwy/Combined MPG',
      electric: {
        camelName: null,
      },
      hybrid: {
        displayName: 'City/Hwy/Combined MPG (Gas only)',
      },
    },
    {
      camelName: null,
      name: 'City/Hwy/Combined MPGe',
      electric: {
        camelName: 'mpge',
        isEvData: true,
      },
      hybrid: {
        camelName: 'mpge',
        name: 'City/Hwy/Combined MPGe (Electric + Gas)',
        isEvData: true,
      },
    },
    {
      camelName: 'fuelTankCapacity',
      name: 'Fuel Capacity',
      electric: {
        camelName: null,
      },
    },
    {
      camelName: 'range',
      name: 'Driving Range (city/hwy)',
      electric: {
        camelName: null,
        forceValue: '<i class="medium">See EPA Electricity Range</i>',
      },
      hybrid: {
        displayName: 'Gas Driving Range (city/hwy)',
      },
    },
    {
      camelName: 'fuelType',
      name: 'Fuel Type',
      path: 'specifications.fuelType',
      categoryCamelName: 'specifications',
    },
  ],
};

export const dimensionsFeatureMap = {
  camelName: 'dimensions',
  name: 'Dimensions',
  values: [
    {
      camelName: 'turningCircle',
      name: 'Turning circle',
    },
    {
      camelName: 'curbWeight',
      name: 'Curb weight',
    },
    {
      camelName: 'groundClearance',
      name: 'Ground Clearance',
    },
    {
      camelName: 'overallWidthWithMirrors',
      name: 'Overall Width with Mirrors',
    },
    {
      camelName: 'overallWidthWithoutMirrors',
      name: 'Overall Width without Mirrors',
    },
    {
      camelName: 'height',
      name: 'Height',
    },
    {
      camelName: 'length',
      name: 'Length',
    },
    {
      camelName: 'bedLength',
      name: 'Bed Length',
      formatter: getBedLengthValue,
      optional: true,
    },
    {
      camelName: 'wheelbase',
      name: 'Wheelbase',
    },
    {
      camelName: 'numberOfSeats',
      name: 'Seating Capacity',
    },
    {
      camelName: 'cargoSpace',
      name: 'Cargo Space',
    },
    {
      camelName: 'frontHeadRoom',
      name: 'Front Head Room',
    },
    {
      camelName: 'frontLegRoom',
      name: 'Front Leg Room',
    },
    {
      camelName: 'frontShoulderRoom',
      name: 'Front Shoulder Room',
    },
    {
      camelName: 'rearHeadRoom',
      name: 'Rear Head Room',
    },
    {
      camelName: 'rearLegRoom',
      name: 'Rear Leg Room',
    },
    {
      camelName: 'rearShoulderRoom',
      name: 'Rear Shoulder Room',
    },
  ],
};

const batteryFeatureMap = {
  isEvSection: true,
  name: 'Battery',
  values: [
    {
      name: 'EPA Electricity Range',
      camelName: null,
      electric: {
        camelName: 'eRange',
        isEvData: true,
      },
      hybrid: {
        camelName: 'eRange',
        isEvData: true,
      },
    },
    {
      name: 'EPA KWh / 100 mi.',
      camelName: null,
      electric: {
        camelName: 'kWhPer100',
        isEvData: true,
      },
      hybrid: {
        camelName: 'kWhPer100',
        isEvData: true,
      },
    },
    {
      name: 'Time To Charge Battery (At 240V)',
      camelName: null,
      electric: {
        camelName: 'timeToCharge',
        isEvData: true,
      },
      hybrid: {
        camelName: 'timeToCharge',
        isEvData: true,
      },
    },
  ],
};

export const featuresMap = [
  warrantyFeatureMap,
  interiorFeatureMap,
  specificationsFeatureMap,
  fuelEconomyFeatureMap,
  batteryFeatureMap,
  dimensionsFeatureMap,
];

export const trimsFeaturesMap = [
  interiorFeatureMap,
  specificationsFeatureMap,
  fuelEconomyFeatureMap,
  batteryFeatureMap,
  dimensionsFeatureMap,
];

export const tcoMap = {
  tcoPrice: 'True Cost to Own®',
  averageCostPerMile: 'Average Cost Per Mile',
  depreciation: 'Depreciation',
  financeInterest: 'Financing',
  taxesAndFees: 'Taxes & Fees',
  fuel: 'Fuel',
  insurance: 'Insurance',
  maintenance: 'Maintenance',
  repairs: 'Repairs',
};
