import { pick, pickBy, identity, forEach, get, set } from 'lodash';
import { PROVIDERS_MAPPING } from 'site-modules/shared/components/profile/auth-providers';
import { getCurrentUser } from 'site-modules/shared/components/profile/firebase-auth';

const FIELDS_TO_SAVE = [
  'appraisalOffers',
  'appraisalOffersHistory',
  'appraisalMultiOffers',
  'appraisalMultiOffersHistory',
  'alerts',
  'leads',
  'mergedAttributes',
  'person',
  'pii',
  'savedSearches',
  'vehicles',
  'lastLeadReference',
  'financing',
  'edmundsMembership',
  'edmundsDeals',
];

const DELIMITER = ' ';
const DEFAULT_VALUE = '';

/**
 * Return date that is ISO format.
 * @returns {string}
 */
export const getSyncDate = () => new Date().toISOString();

const getSavableVinData = idm => {
  // eslint-disable-next-line no-param-reassign
  forEach(get(idm, 'vehicles.vins', {}), vehicle => delete vehicle.submittedTs);
  return idm;
};

/**
 * Get all defined savable data.
 * Savable Data: See "FIELDS_TO_SAVE".
 * @param {Object} idm data
 * @returns {Object} savable data without null or undefined values
 */
export const getSavableData = idm => getSavableVinData(pick(pickBy(idm, identity), FIELDS_TO_SAVE));

export const splitUserName = userName => {
  const split = (userName || DEFAULT_VALUE).trim().split(DELIMITER);

  return {
    first: split.shift(),
    last: split.join(DELIMITER),
  };
};

export const getSocialProfile = profile =>
  getCurrentUser().then(user => {
    const userProfile = {
      ...profile,
      identifiers: {
        ...profile?.identifiers,
      },
    };
    const providers = user?.providerData;
    if (providers?.length) {
      providers.forEach(provider => {
        userProfile.identifiers[PROVIDERS_MAPPING[provider.providerId]] = [provider.uid];
      });
      const provider = providers[0];
      if (!provider.email) {
        return Promise.reject({ code: `verify/no-email-${provider.providerId ?? 'unknown'}` });
      }
      userProfile.identifiers.email = [provider.email];
      userProfile.identifiers.email = [provider.email];
      const name = splitUserName(provider.displayName);
      if (name.first) {
        set(userProfile, 'person.firstName', name.first);
      }
      if (name.last) {
        set(userProfile, 'pii.lastName', name.last);
      }
    }
    return userProfile;
  });
