import { PARTNERS } from './partners';

/**
 * Partners, whose offers can be solicited.
 * @type {Array}
 */
export const SOLICITATION_SUPPORTED_PARTNERS = [
  PARTNERS.CARWISER3,
  PARTNERS.AUTONATION,
  PARTNERS.CARMAX,
  PARTNERS.CASHFORCARS,
  PARTNERS.ECHOPARK,
];

/**
 * Partners, whose offers can be displayed.
 * @type {Array}
 */
export const DISPLAY_SUPPORTED_PARTNERS = [
  {
    partnerToSolicit: PARTNERS.CARWISER,
    partnerKey: PARTNERS.CARWISER,
  },
  {
    partnerToSolicit: PARTNERS.CARWISER3,
    partnerKey: PARTNERS.CARWISER,
  },
  {
    partnerToSolicit: PARTNERS.AUTONATION,
    partnerKey: PARTNERS.AUTONATION,
  },
  {
    partnerToSolicit: PARTNERS.CASHFORCARS,
    partnerKey: PARTNERS.CASHFORCARS,
  },
  {
    partnerToSolicit: PARTNERS.ECHOPARK,
    partnerKey: PARTNERS.ECHOPARK,
  },
];
