export const VENOM_TEST_HEADER_NAME = 'x-venom-test';

/**
 *
 * @param {Object} res The response
 * @param {Object} state The state
 */
export const setVenomTestHeader = (res, state) => {
  const testHeaders = state?.pageContext?.server?.venomTestHeader ?? {};
  const entries = Object.entries(testHeaders);

  if (entries.length > 0) {
    const testHeaderValue = entries.map(([key, value]) => `${key}=${value}`).join(',');
    res.setHeader(VENOM_TEST_HEADER_NAME, testHeaderValue);
  }
};
