import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const kiaAltFuelShowroomRoutes = [
  {
    /**
     * Route for "kia" Alt Fuel Showroom page
     * https://www.edmunds.com/kia-alt-fuel-showroom/
     */
    path: '/kia-alt-fuel-showroom',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "kia-alt-fuel-showroom" */ '../pages/kia-alt-fuel-showroom/kia-alt-fuel-showroom-definition')
    ),
    chunkName: 'kia-alt-fuel-showroom',
    enableLooseEtag: true,
  },
];
