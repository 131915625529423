import { combineReducers } from 'redux';
import { publishedExperiments } from './published-experiments-reducer';
import { computedExperiment } from './computed-experiment-reducer';
import { pageExperiment } from './page-experiment-reducer';
import { experimentPageContextReducer } from './experiment-page-context-reducer';
import { emptyReducer } from './empty-reducer';
import { exposureRecordReducer } from './exposure-record-reducer';

/**
 * Reducer combining all reducers related to AB-testing
 *
 * @type {Function}
 */
export const experimentReducer = combineReducers({
  published: publishedExperiments,
  computed: computedExperiment,
  page: pageExperiment,
  pageContext: experimentPageContextReducer,
  inspectletExperiments: emptyReducer,
  exposureRecord: exposureRecordReducer,
});
