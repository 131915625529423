const getCookies = () => {
  const cookies = {};
  const rawCookies = document.cookie.split(';');

  rawCookies.forEach(cookie => {
    const [key, value] = cookie.split('=').map(c => c.trim());
    cookies[key] = decodeURIComponent(value);
  });

  return cookies;
};

export const getCookie = cookieName => {
  const cookies = getCookies();
  return cookies[cookieName];
};
