import PropTypes from 'prop-types';
import { get, uniqBy, flatMap, isEmpty, compact, merge, pick, round } from 'lodash';
import {
  sortQuestionsByDisplayOrder,
  sortOffersByValuation,
  acceptedOfferFilter,
  constructOfferObject,
  getOfferCountTrackingMap,
  getOfferDisplayCountTrackingMap,
  getMultiOfferResultsObject,
  getOfferDetailsPageObject,
  sortOffersByValuationAndExpiredStatus,
  getSolicitedPartners,
  filterPartnerData,
  getSolicitedPartnersTrackingMap,
  getCarwiserSolicitedPartnerResponseStatusCodeTrackingMap,
  getOfferBrandsTrackingMap,
  getRedemptionStateByPartner,
  areOffersPending,
  getSellerAbTestTrackingMap,
  getMultiOfferTrackingMap,
  getOffersPendingTrackingMap,
  processEchoparkOffers,
  getDeclineReasonTrackingMap,
} from 'client/data/utils/multi-offer';
import { isEstimatedOffer } from 'site-modules/multi-offer/utils/offers';
import { transformQuestions } from 'client/data/transforms/appraisal/transform-questions';
import { buildAllTmvBandsUrl } from 'client/data/models/vehicle-v2';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { withMetrics } from 'client/data/api/api-metrics';
import { EdmundsMultiOfferGatewayAPI, EdmundsMultiOfferAPI, EdmundsAPI } from 'client/data/api/api-client';
import { BRANDS } from 'site-modules/multi-offer/constants/brands';
import { PARTNERS } from 'site-modules/shared/constants/multi-offer/partners';
import { logger } from 'client/utils/isomorphic-logger';
import { PageModel } from 'client/data/models/page';
import { buildClosestIcoDealersPath, DealerIcoWidgetModel } from 'client/data/models/dealer-ico-widget';
import { hasMultiOfferPartners } from 'site-modules/shared/utils/multi-offer/multi-offer';
import { removeSpecialCharactersForEdwPixel } from 'site-modules/shared/utils/tracking';

export const VENOM_X_PRODUCT_ID_HEADER = { 'X-Product-Id': 'venom' };

export const sendEmailFromMods = async ({ modsId, email, data }) => {
  const payload = {
    email,
    ...data,
  };

  try {
    await EdmundsMultiOfferAPI.fetchJson(`/v1/offer-events/${modsId}/email`, {
      headers: { ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
      retries: 1,
      method: 'POST',
      body: JSON.stringify(payload),
      includeResponseInError: true,
    });
    return true;
  } catch (e) {
    logger('warn', e, 'Failed send email to MODS');
    return false;
  }
};

const getErrorMessage = responseText => {
  if (!responseText) {
    // It's useful to know exactly what "empty" response we have.
    return responseText;
  }
  try {
    const json = JSON.parse(responseText);
    return json && json.message;
  } catch (e) {
    return removeSpecialCharactersForEdwPixel(responseText);
  }
};

export const createModsRecord = async (payload, options = {}) => {
  let response = {};

  try {
    response = await EdmundsMultiOfferAPI.fetchJson('/v1/offer-events', {
      headers: { ...options.headers, ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
      retries: 1,
      method: 'POST',
      body: JSON.stringify(payload),
      includeResponseInError: true,
    });
  } catch (e) {
    logger('warn', e, 'Failed multi-offer record creation');
    response = { isError: true, message: getErrorMessage(e.responseText) };
  }

  return response;
};

export const makePrequalifyRequest = async (stateCode, dma, year, mileage, context) => {
  const inputs = { stateCode, dma };
  if (year) inputs.year = +year;
  if (mileage) inputs.mileage = +mileage;

  const api = context ? withMetrics(EdmundsMultiOfferAPI, context) : EdmundsMultiOfferAPI;

  try {
    return await api.fetchJson(`/v1/partners/prequalify`, {
      headers: {
        ...VENOM_X_PRODUCT_ID_HEADER,
        'content-type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ inputs }),
      showAPIError: true,
    });
  } catch (e) {
    logger('warn', e, `Failed multi-offer prequalify for stateCode=${stateCode} dma=${dma} mileage=${mileage}`);
    return context ? {} : { isError: true, message: getErrorMessage(e.responseText) };
  }
};

export const solicitMoreOffers = async (modsId, payload, options = {}) => {
  let response = {};
  try {
    response = await EdmundsMultiOfferAPI.fetchJson(`/v1/offer-events/${modsId}/solicit-more-offers`, {
      headers: { ...options.headers, ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
      retries: 1,
      method: 'POST',
      body: JSON.stringify(payload),
      showAPIError: true,
    });
  } catch (e) {
    logger('warn', e, 'Failed multi-offer solicitation for more offers');
    response = { isError: true, message: e.message, status: e.status };
  }

  return response;
};

export const requestRedemptionInfo = async (modsId, payload, options = {}) => {
  let response = {};
  try {
    response = await EdmundsMultiOfferAPI.fetchJson(`/v1/offer-events/${modsId}/request-redemption-info`, {
      headers: { ...options.headers, ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
      retries: 1,
      method: 'POST',
      body: JSON.stringify(payload),
      showAPIError: true,
    });
  } catch (e) {
    logger('warn', e, 'Failed multi-offer request redemption info');
    response = { isError: true, message: e.message, status: e.status };
  }

  return response;
};

export const appendAbTestBucketing = async (modsId, payload, options = {}) => {
  try {
    return await EdmundsMultiOfferAPI.fetchJson(`/v1/offer-events/${modsId}/append-abtest`, {
      headers: { ...options.headers, ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
      retries: 1,
      method: 'PATCH',
      body: JSON.stringify(payload),
      showAPIError: true,
    });
  } catch (e) {
    logger('warn', e, 'Failed multi-offer append AB test bucketing');
    return { isError: true, message: e.message, status: e.status };
  }
};

export const getTmvByModsData = async (modsData, userMileage, options = {}) => {
  try {
    const vehicle = get(modsData, 'vehicle');
    const zipCode = get(modsData, 'seller.zipCode');

    if (!zipCode || isEmpty(vehicle) || !userMileage) return null;

    const apiUrl = buildAllTmvBandsUrl({
      styleId: vehicle.edmundsStyleId,
      zipCode,
      mileage: userMileage,
      colorId: get(vehicle, 'colors.exterior.id'),
      optionIds: compact(get(vehicle, 'selectedOptions', []).map(({ id }) => id)).join(','),
    });

    return await EdmundsAPI.fetchJson(apiUrl, {
      headers: { ...options.headers },
      showAPIError: true,
    });
  } catch (e) {
    logger('warn', e, 'Failed TMV request');
    return { isError: true, message: e.message, status: e.status };
  }
};

const OfferDetailsEntity = PropTypes.shape({
  code: PropTypes.string,
  offerUrl: PropTypes.string,
  valuation: PropTypes.number,
  printUrl: PropTypes.string,
  // Store object that contains raw/stringified data.
  store: PropTypes.shape({
    rawData: PropTypes.string,
  }),
  // Store object that contains store fields (like name, city, etc.).
  storeData: PropTypes.shape({}),
  expiresDisplayDate: PropTypes.string,
  expiresDateUtc: PropTypes.string,
  status: PropTypes.string,
  brand: PropTypes.string,
  source: PropTypes.string,
  partner: PropTypes.string,
  /**
   * Number of the offer as it's stored in the array from model (i.e. the highest offer is the 1st).
   * Starts with 1.
   */
  offerNumber: PropTypes.number,
  /**
   * Number of the offer as it's displayed on the page (i.e. the highest offer MAY NOT be the 1st).
   * Starts with 1.
   */
  offerDisplayNumber: PropTypes.number,
  isObscured: PropTypes.bool,
  /**
   * Flag that indicates if the redemption info has been requested for this offer.
   *
   * NOTE: this field comes from MODS.
   */
  offerOfInterest: PropTypes.bool,
  /**
   * Http status (e.g. 200) of the response for the request redemption info call.
   * May be null if the call failed without response (e.g. network error).
   * If the call wasn't made then this should be undefined.
   *
   * NOTE: this field comes from MODS.
   */
  redemptionInfoResponse: PropTypes.number,
  /**
   * Indicates the offer state in relation to requesting the redemption info (which includes submitting PII).
   *
   * NOTE: this field is computed in FE code.
   */
  redemptionState: PropTypes.string,
});

const VehicleDetailsEntity = PropTypes.shape({
  chromeStyleId: PropTypes.string,
  condition: PropTypes.string,
  drive: PropTypes.string,
  edmundsStyleId: PropTypes.number,
  engineSize: PropTypes.number,
  exteriorColor: PropTypes.string,
  interiorColor: PropTypes.string,
  kmxStyleSku: PropTypes.string,
  make: PropTypes.string,
  makeSlug: PropTypes.string,
  mileage: PropTypes.number,
  model: PropTypes.string,
  modelSlug: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      oemCode: PropTypes.string,
    })
  ),
  styleName: PropTypes.string,
  subModel: PropTypes.string,
  subModelSlug: PropTypes.string,
  tmv: PropTypes.shape({
    average: PropTypes.number,
    outstanding: PropTypes.number,
    rough: PropTypes.number,
    clean: PropTypes.number,
  }),
  transmissionType: PropTypes.string,
  vin: PropTypes.string,
  year: PropTypes.number,
});

const QuestionsEntity = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    desc: PropTypes.string,
    category: PropTypes.string,
    answerType: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        desc: PropTypes.string,
        answerType: PropTypes.string,
        detailQuestions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            desc: PropTypes.string,
            answerType: PropTypes.string,
            answers: PropTypes.arrayOf(PropTypes.shape({})),
            partner: PropTypes.string,
          })
        ),
      })
    ),
    partner: PropTypes.string,
  })
);

export const MultiOfferEntities = {
  QuestionsData: PropTypes.shape({
    questions: QuestionsEntity,
  }),
  Questions: QuestionsEntity,
  OfferDetails: OfferDetailsEntity,
  VehicleDetails: VehicleDetailsEntity,
  OffersResultsDetails: PropTypes.shape({
    offers: PropTypes.arrayOf(OfferDetailsEntity),
    vehicle: VehicleDetailsEntity,
    pageloadTrackingBySubactionName: PropTypes.shape({}),
    renderType: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    seller: PropTypes.shape({
      zipCode: PropTypes.string,
    }),
  }),
  OfferDetailsPageData: PropTypes.shape({
    offer: OfferDetailsEntity,
    vehicle: VehicleDetailsEntity,
    pageloadTrackingBySubactionName: PropTypes.shape({}),
    renderType: PropTypes.string,
    seller: PropTypes.shape({
      zipCode: PropTypes.string,
    }),
  }),
};

export const questionsPath = {
  build: () => 'questions', // Used specifically to "build" the model segment path. Example: `zipCode["${zipCode}"].make["${make}"].model["${model}"].year["${year}"]`
  segment: 'questions', // Used specifically for model segment. Example: 'zipCode["{zipCode}"].make["{make}"].model["{model}"].year["{year}"]'
};
/**
 * Multi-offer questions transformed for usage in VAC Step 4.
 */
export const transformedQuestionsPath = {
  build: () => 'transformedQuestions',
  segment: 'transformedQuestions',
};

/**
 * Raw MODS (Multi-Offer Data Store) record path.
 *
 * This path contains the MODS record as it is returned by MOWS, i.e. WITHOUT any post-processing.
 *
 * IMPORTANT: this path is intended ONLY for the WebSocket functionality.
 */
export const rawModsRecordPath = {
  build: modsId => `modsId["${modsId}"].rawRecord`,
  segment: 'modsId["{modsId}"].rawRecord',
};

/**
 * MODS (Multi-Offer Data Store) record path.
 *
 * This path is ready to be used by the UI components.
 */
export const modsRecordPath = {
  build: modsId => `modsId["${modsId}"].record`,
  segment: 'modsId["{modsId}"].record',
};

/**
 * MOR (Multi-Offer Results) path.
 */
export const offersResultsPath = {
  build: modsId => `modsId["${modsId}"].results`,
  segment: 'modsId["{modsId}"].results',
};

/**
 * ODP (Offer Details Page) path.
 */
export const offerDetailsPath = {
  build: modsId => `modsId["${modsId}"].offerDetails`,
  segment: 'modsId["{modsId}"].offerDetails',
};

/**
 * Special path for 'seller' and 'vehicle' data from MODS, that are required to build TMV call params.
 */
export const tmvParamsPath = {
  build: () => 'tmvParams',
  segment: 'tmvParams',
};

export const tmvDataPath = {
  build: modsId => `modsId["${modsId}"].tmvData`,
  segment: 'modsId["{modsId}"].tmvData',
};

export const searchByVinPath = {
  build: vin => `vin["${vin}"].searchByVin`,
  segment: `vin["{vin}"].searchByVin`,
};

export const offerRenewalModsDataPath = {
  build: ({ vin, modsId }) => `vin["${vin}"].modsId["${modsId || 'null'}"].offerRenewalModsData`,
  segment: `vin["{vin}"].modsId["{modsId}"].offerRenewalModsData`,
};

export const prequalificationPath = {
  build: ({ stateCode, dma, year }) => `stateCode["${stateCode}"].dma["${dma}"].year["${year}"].prequalification`,
  segment: `stateCode["{stateCode}"].dma["{dma}"].year["{year}"].prequalification`,
};

export const icoDealerPath = {
  build: ({ zipCode }) => (zipCode ? `zipCode["${zipCode}"].icoDealer` : ''),
  segment: `zipCode["{zipCode}"].icoDealer`,
};

const transformToModsData = modsRecord => {
  const { vehicle, conditionDetails, seller, createdAt } = modsRecord;
  const kmxConditionQuestions = JSON.parse(get(conditionDetails, 'kmx.rawData', '[]'));
  const carwiserConditionQuestions = JSON.parse(get(conditionDetails, 'carwiser.rawData', '[]'));
  const offersFinalizedAt = get(modsRecord, 'partners.kmx.offersFinalizedAt');

  return {
    createdAt,
    vehicle: {
      ...vehicle,
      vin: modsRecord.vin,
    },
    kmxConditionQuestions,
    seller,
    kmx: {
      offersFinalizedAt,
      offerDetails: get(modsRecord, 'partners.kmx.offers[0]'),
    },
    carwiser: {
      conditionQuestions: carwiserConditionQuestions,
    },
  };
};

export const MultiOfferModel = createModelSegment('multiOffer', [
  {
    path: questionsPath.segment,
    async resolve(match, context) {
      const result = {};
      let questions = [];

      try {
        questions = await withMetrics(EdmundsMultiOfferGatewayAPI, context).fetchJson('/v1/condition-questions');
      } catch (e) {
        logger('warn', e, 'Failed retrieving multi-offer condition questions');
        result.isError = true;
        result.message = e.message;
        result.status = e.status;
      }

      const sortedQuestions = sortQuestionsByDisplayOrder(questions).map(({ answers, ...question }) => ({
        ...question,
        answers: answers.map(answer => {
          const { detailQuestions } = answer;
          return {
            ...answer,
            detailQuestions: detailQuestions ? sortQuestionsByDisplayOrder(detailQuestions) : detailQuestions,
          };
        }),
      }));

      return Promise.resolve({
        ...result,
        questions: sortedQuestions,
      });
    },
  },
  {
    path: transformedQuestionsPath.segment,
    async resolve(match, context) {
      const questionsData = await context.resolveValue(questionsPath.build());
      const questions = get(questionsData, 'questions', []);
      return transformQuestions(questions);
    },
  },
  {
    path: rawModsRecordPath.segment,
    async resolve(match, context) {
      const { modsId } = match;

      try {
        // IMPORTANT: Using `await` here is critical, otherwise the error will NOT be thrown/caught HERE if the mods
        // record is not found (and as a result, the "custom 404 page" feature will not work).
        return await withMetrics(EdmundsMultiOfferAPI, context).fetchJson(`/v1/offer-events/${modsId}`, {
          headers: {
            ...VENOM_X_PRODUCT_ID_HEADER,
          },
        });
      } catch (e) {
        logger('warn', e, `Failed retrieving MODS record from ${modsId}`);
        return {};
      }
    },
  },
  {
    path: modsRecordPath.segment,
    async resolve(match, context) {
      const { modsId } = match;

      const record = await context.resolveValue(rawModsRecordPath.build(modsId));

      if (isEmpty(record)) {
        return {};
      }

      // newer records don't have 'vin' in 'vehicle', so fill it in, since the UI components expect it.
      const results = {
        ...record,
        vehicle: {
          ...record.vehicle,
          vin: record.vin,
        },
      };

      /**
       * Update parameters for TMV so it can be used and cached. Cache should bust when the actual data is changed.
       * See EMO-913 for more details.
       */
      await context.updateValue(tmvParamsPath.build(), { seller: results.seller, vehicle: results.vehicle });

      return results;
    },
  },
  {
    path: offersResultsPath.segment,
    async resolve(match, context) {
      const { modsId } = match;
      const finalOffers = [];
      const pageloadTrackingBySubactionName = {};
      let offersArePending = false;
      let partnerData = {};
      let seller = {};

      const data = await context.resolveValue(modsRecordPath.build(modsId), MultiOfferModel);
      if (isEmpty(data)) {
        return getMultiOfferResultsObject({ isError: true });
      }
      partnerData = get(data, 'partners', {});
      const kmxPartnerObject = partnerData[BRANDS.KMX];
      const kmxOffer = get(kmxPartnerObject, ['offers', 0]);
      finalOffers.push(constructOfferObject(kmxOffer, BRANDS.KMX, kmxPartnerObject));
      const createdAt = get(data, 'createdAt');
      const updatedAt = get(data, 'updatedAt');
      seller = get(data, 'seller');

      const vehicle = get(data, ['vehicle'], {});
      const location = await context.resolveValue('location', PageModel);

      const solicitedPartners = getSolicitedPartners(get(data, 'partnersToSolicit'));

      // Extracts out all offers
      const filteredPartnerData = filterPartnerData(partnerData, solicitedPartners);
      const flattenedOffers = flatMap(filteredPartnerData, (partnerObject, partnerLabel) => {
        const { offers, solicitedResponse } = partnerObject;

        /**
         * START strictly used for setting "offersArePending" boolean START
         */
        if (!offersArePending && areOffersPending({ offers, solicitedResponse }, location)) {
          offersArePending = true;
        }
        /**
         * END strictly used for setting "offersArePending" boolean END
         */

        if (partnerLabel === BRANDS.KMX || isEmpty(offers)) {
          return [];
        }

        // From this point on "offers" is defined and exist
        return offers.map(offer => constructOfferObject(offer, partnerLabel, partnerObject));
      });
      const flattenedAcceptedOffers = flattenedOffers.filter(acceptedOfferFilter); // Get offers that are accepted with valuation

      // Includes page load tracking for BOTH pending and offer finalized.
      merge(
        pageloadTrackingBySubactionName,
        getSolicitedPartnersTrackingMap(solicitedPartners),
        getCarwiserSolicitedPartnerResponseStatusCodeTrackingMap(partnerData),
        getSellerAbTestTrackingMap(seller),
        getDeclineReasonTrackingMap(flattenedOffers, BRANDS.ECHOPARK, PARTNERS.ECHOPARK)
      );

      const redemptionStateByPartner = getRedemptionStateByPartner(partnerData);

      // If offersArePending, return "non concatenated offers" IMMEDIATELY! So it does not do any proccessing.
      if (offersArePending) {
        merge(pageloadTrackingBySubactionName, getOffersPendingTrackingMap());
        return getMultiOfferResultsObject({
          offers: finalOffers,
          vehicle,
          pageloadTrackingBySubactionName,
          offersArePending,
          createdAt,
          updatedAt,
          seller,
          redemptionStateByPartner,
        });
      }

      // Process EchoPark offers, and combine the result offers with CarMax offer.
      const offersBeforeDedupe = finalOffers.concat(processEchoparkOffers(flattenedAcceptedOffers));

      // Sort offers by valuation, and remove duplicate brand offers
      const uniqOffers = uniqBy(offersBeforeDedupe.sort(sortOffersByValuation), 'brand');

      // Take the top three offers, then sort by expiration date.
      const offers = uniqOffers.slice(0, 3).sort(sortOffersByValuationAndExpiredStatus);

      // Includes page load tracking when offers are finalized (no longer pending)
      merge(
        pageloadTrackingBySubactionName,
        getOfferCountTrackingMap(offers, data, flattenedOffers),
        getOfferDisplayCountTrackingMap(offers),
        getOfferBrandsTrackingMap(offers)
      );

      return getMultiOfferResultsObject({
        offers,
        vehicle,
        pageloadTrackingBySubactionName,
        offersArePending,
        createdAt,
        updatedAt,
        seller,
        redemptionStateByPartner,
      });
    },
  },
  {
    path: offerDetailsPath.segment,
    async resolve(match, context) {
      const { modsId } = match;

      const record = await context.resolveValue(modsRecordPath.build(modsId), MultiOfferModel);

      if (isEmpty(record)) {
        return getOfferDetailsPageObject({ isError: true });
      }

      const partnerData = get(record, 'partners', {});

      // currently ODP page displays only CarMax offer.
      const partnerObject = partnerData[BRANDS.KMX];
      const offerData = get(partnerObject, ['offers', 0]);
      const offer = constructOfferObject(offerData, BRANDS.KMX, partnerObject);

      const vehicle = get(record, 'vehicle', {});
      const seller = get(record, 'seller', {});
      const offers = [offer];

      const partnersToSolicit = get(record, 'partnersToSolicit');
      const isMultiOffer = hasMultiOfferPartners(partnersToSolicit);
      const solicitedPartners = getSolicitedPartners(partnersToSolicit);

      const isEstimated = isEstimatedOffer(offer);

      const pageloadTrackingBySubactionName = {
        ...getOfferCountTrackingMap(offers, record),
        ...(isEstimated ? {} : getOfferDisplayCountTrackingMap(offers)),
        ...getSolicitedPartnersTrackingMap(solicitedPartners),
        ...getMultiOfferTrackingMap(isMultiOffer),
        ...getSellerAbTestTrackingMap(seller),
      };

      return getOfferDetailsPageObject({
        offer,
        vehicle,
        pageloadTrackingBySubactionName,
        seller,
        isMultiOffer,
      });
    },
  },
  {
    path: searchByVinPath.segment,
    async resolve({ vin }, context) {
      try {
        const data = await withMetrics(EdmundsMultiOfferAPI, context).fetchJson(`/v1/offer-events/search-by-vin`, {
          headers: {
            ...VENOM_X_PRODUCT_ID_HEADER,
            'content-type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({ vin: vin.toUpperCase() }),
        });
        const offerInfo = get(data, '0', {});
        return transformToModsData(offerInfo);
      } catch (e) {
        logger('warn', e, `Failed retrieving MODS record by VIN ${vin}`);
        return {};
      }
    },
  },
  {
    path: offerRenewalModsDataPath.segment,
    async resolve({ vin, modsId }, context) {
      if (modsId && modsId !== 'null') {
        const modsRecord = await context.resolveValue(modsRecordPath.build(modsId), MultiOfferModel);
        const kmxRefreshedOffers = modsRecord?.refreshedOffers?.kmx?.offers;
        if (!isEmpty(kmxRefreshedOffers)) {
          const modsData = transformToModsData(modsRecord);
          return {
            ...modsData,
            kmx: {
              ...modsData.kmx,
              // Take the latest refreshed offer.
              refreshedOffer: kmxRefreshedOffers[kmxRefreshedOffers.length - 1],
            },
          };
        }
      }
      return context.resolveValue(searchByVinPath.build(vin), MultiOfferModel);
    },
  },
  {
    path: prequalificationPath.segment,
    async resolve({ stateCode, dma, year, mileage }, context) {
      return makePrequalifyRequest(stateCode, dma, year, mileage, context);
    },
  },
  {
    /**
     * Used specifically to store the TMV parameters so it can be resolved in other paths
     * a resolver is not needed because we will be updating the value independently.
     *
     * See EMO-913 for more details.
     */
    path: tmvParamsPath.segment,
  },
  {
    path: tmvDataPath.segment,
    async resolve({ modsId }, context) {
      const dataFromTmvParams = await context.resolveValue(tmvParamsPath.build());
      let vehicle = get(dataFromTmvParams, 'vehicle');
      let zipCode = get(dataFromTmvParams, 'seller.zipCode');

      if (isEmpty(dataFromTmvParams)) {
        // Get data directly from MODS if "tmvParams" is not available.
        const data = await context.resolveValue(modsRecordPath.build(modsId));
        vehicle = get(data, 'vehicle');
        zipCode = get(data, 'seller.zipCode');
      }

      if (!zipCode || isEmpty(vehicle)) return null;

      const apiUrl = buildAllTmvBandsUrl({
        styleId: vehicle.edmundsStyleId,
        zipCode,
        mileage: vehicle.mileage,
        colorId: get(vehicle, 'colors.exterior.id'),
        optionIds: compact(get(vehicle, 'selectedOptions', []).map(({ id }) => id)).join(','),
      });

      let tmvData;
      try {
        tmvData = await withMetrics(EdmundsAPI, context).fetchJson(apiUrl);
      } catch (e) {
        logger('warn', e, 'Failed TMV request');
        tmvData = { isError: true, message: e.message, status: e.status };
      }

      return tmvData;
    },
  },
  {
    path: icoDealerPath.segment,
    async resolve({ zipCode }, context) {
      const icoDealersPath = buildClosestIcoDealersPath({
        zipCode,
        numIcoDealers: 1,
        voiType: 'newOptional,allOptional,usedOptional,noVehicle',
      });
      const icoDealers = await context.resolveValue(icoDealersPath, DealerIcoWidgetModel);
      const dealerId = icoDealers?.multiDealerIds?.[0];
      if (!dealerId) {
        return {};
      }
      try {
        const queryParams = {
          fields:
            'location,address,franchises,name,productFeatures(types,productAttributes),make,phoneNumbers,partnerFeedMappings,logoUrl',
          zip: zipCode,
        };
        const queryString = Object.entries(queryParams)
          .map(([name, value]) => `${name}=${value}`)
          .join('&');
        const dealerInfo = await withMetrics(EdmundsAPI, context).fetchJson(
          `/dealer/v5/dealership/${dealerId}/franchises?${queryString}`
        );
        const { name, address, distance, phoneNumbers } = dealerInfo || {};
        return {
          id: dealerId,
          name,
          address: pick(address, ['street', 'city', 'stateCode', 'zip']),
          distance: round(distance, 2),
          phoneNumbers,
        };
      } catch (e) {
        logger('warn', e, 'Failed dealership franchises request');
        return { id: dealerId };
      }
    },
  },
]);
