/**
 * this file was created to help with unit testing.
 * originally the fetch was defined in graphql-client.js but moving it here makes it easy to mock it in graphql-client.test.js
 */
import 'isomorphic-fetch';
import { isNode } from 'client/utils/environment';

export function handleFetch(url, options) {
  if (isNode()) {
    const linkText = decodeURIComponent(url); // todo don't log this in prod cause it's expensive
    global.logger.debug(`[GRAPHQL] fetch \x1b]8;;${url}\x07${linkText}\x1b]8;;\x07`);
  }
  return fetch(url, options);
}
