import { TrackingConstant } from 'client/tracking/constant';

export const getSquishVinNoMmyPixelValue = (vin, response) => {
  const { hasError, status, isPubStateInvalid } = response ?? {};

  const resultStatus = hasError ? status : 200;

  if (isPubStateInvalid) return `${vin}_${resultStatus}_invalid_publication_status`;

  return `${vin}_${resultStatus}_${TrackingConstant.SUBACTION_SQUISHVIN_DECODE}_${TrackingConstant.MMY_NOT_FOUND}`;
};
