import dateFormat from 'dateformat';
import { get } from 'lodash';
import { TrackingConstant } from 'client/tracking/constant';
import { TIME } from 'client/utils/time';
import { EventToolbox } from 'client/utils/event-toolbox';
import { getCurrentDate } from 'site-modules/shared/utils/date-utils';
import {
  APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
  DEFAULT_MILEAGE,
  PARTNER_OFFER_TRACKING_CREATIVE_ID,
} from 'site-modules/shared/constants/appraisal/appraisal';
import { numberWithCommasV2 } from 'client/site-modules/shared/utils/string';
import { formatPriceStringV2 } from 'site-modules/shared/utils/price-utils';
import { getSquishVinNoMmyPixelValue } from 'site-modules/shared/utils/appraisal/tracking';

export const SELECT_DISTANCE_EVENT_DATA = creativeId => ({
  creative_id: creativeId,
  action_name: TrackingConstant.ACTION_RECEIVE_EPO,
  action_category: TrackingConstant.USER_ACTION_CATEGORY,
  action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
});

export const fireQuoteRecencyTrackingPixel = offerCreatedDateUtc => {
  if (offerCreatedDateUtc) {
    const currentDate = getCurrentDate().getTime();
    const createdDate = new Date(offerCreatedDateUtc).getTime();
    const numberOfFullDays = Math.floor((currentDate - createdDate) / TIME.MS.ONE_DAY);
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        subaction_name: TrackingConstant.ACTION_QUOTE_RECENCY,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        creative_id: 'edm-entry-partner-offer',
        value: numberOfFullDays,
      },
    });
  }
};

export const fireAbTestBucketing = (creativeId, modsRecord = {}) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      subaction_name: TrackingConstant.MODS_AB_TEST_BUCKETING,
      action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
      creative_id: creativeId,
      value: modsRecord.seller?.abTestBucketing?.join(',') || null,
    },
  });
};
export const fireModsIdBucketing = (creativeId, value = null) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      subaction_name: TrackingConstant.MODS_ID,
      action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
      creative_id: creativeId,
      value,
    },
  });
};

const fireEstimatedOffer = (value, subaction) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: subaction,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
      value,
    },
  });
};
export const fireEstimatedOfferRange = (min, max, subaction) => {
  fireEstimatedOffer(`${min} - ${max}`, subaction);
  fireEstimatedOffer(String(min), `${subaction}_min_value`);
  fireEstimatedOffer(String(max), `${subaction}_max_value`);
};

export function getDefaultMileage(styleYear) {
  const currentDate = getCurrentDate();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0..11
  const twoMonthsAgo = currentMonth - 2;
  const monthCount = twoMonthsAgo > 0 ? twoMonthsAgo : 0;
  const yearAge = currentYear - styleYear;
  if (yearAge < 0 || (yearAge === 0 && monthCount === 0)) {
    return DEFAULT_MILEAGE.MIN;
  }
  return yearAge >= 11 ? 130000 : yearAge * 12000 + monthCount * 1000;
}

export function getMileageRangeMessage(year, make, model) {
  const defaultMileage = getDefaultMileage(year);
  const max = defaultMileage + DEFAULT_MILEAGE.THRESHOLD;
  const min =
    defaultMileage > DEFAULT_MILEAGE.THRESHOLD ? defaultMileage - DEFAULT_MILEAGE.THRESHOLD : DEFAULT_MILEAGE.MIN;
  return `In your area, the average mileage for a ${year} ${make} ${model} is between ${numberWithCommasV2(
    min
  )} - ${numberWithCommasV2(max)} miles.`;
}

/**
 * Format the expired date display to match our UI/UX if it's formatted differently
 * @param {String} offerExpiredDate
 * @returns {String}
 */
export const getExpiresDisplayDate = expiresDisplayDate => {
  try {
    return dateFormat(expiresDisplayDate, 'mm/dd/yyyy');
  } catch (e) {
    // we should NOT expect a value that can be parsed as a date from the "expiresDisplayDate" field.
    return expiresDisplayDate;
  }
};

export function getAppraisalValues({ appraisalStyles = [], estimatedStyleId }) {
  if (!appraisalStyles) return {};

  const selectedStyleAppraisalValues = appraisalStyles.find(style => style.styleId === estimatedStyleId);
  const cleanTradeInValue = get(selectedStyleAppraisalValues, 'tmv.conditions.CLEAN.usedTradeIn');
  const cleanPrivatePartyValue = get(selectedStyleAppraisalValues, 'tmv.conditions.CLEAN.usedPrivateParty');
  let minAppraisalValue;
  let maxAppraisalValue;

  appraisalStyles.forEach(style => {
    const styleMinAppraisalValue = get(style, 'tmv.conditions.ROUGH.usedTradeIn');
    const styleMaxAppraisalValue = get(style, 'tmv.conditions.OUTSTANDING.usedTmvRetail');

    if (!minAppraisalValue || styleMinAppraisalValue < minAppraisalValue) {
      minAppraisalValue = styleMinAppraisalValue;
    }

    if (!maxAppraisalValue || styleMaxAppraisalValue > maxAppraisalValue) {
      maxAppraisalValue = styleMaxAppraisalValue;
    }
  });

  return {
    minAppraisalValue: minAppraisalValue ? formatPriceStringV2(minAppraisalValue) : null,
    maxAppraisalValue: maxAppraisalValue ? formatPriceStringV2(maxAppraisalValue) : null,
    cleanTradeInValue: cleanTradeInValue ? formatPriceStringV2(cleanTradeInValue) : null,
    cleanPrivatePartyValue: cleanPrivatePartyValue ? formatPriceStringV2(cleanPrivatePartyValue) : null,
  };
}

export const fireAppraisalPixel = (value, creativeId = APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: 'vin_source',
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      creative_id: creativeId,
      value,
    },
  });
};

export const fireAppraisalHowManyVinsPixel = (
  subactionName,
  value,
  creativeId = APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID
) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: subactionName,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      creative_id: creativeId,
      value,
    },
  });
};

export const fireSelectVinPixel = (value, creativeId = APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: TrackingConstant.SUBACTION_VIN_ENTRY,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      creative_id: creativeId,
      value,
    },
  });
};

export const fireLicensePlateServicePixel = (
  subActionName,
  value = null,
  creativeId = APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID
) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: subActionName,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      creative_id: creativeId,
      value,
    },
  });
};

export const fireVinErrorTracking = (vin, vinStylesResponse, creativeId) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      creative_id: creativeId,
      subaction_name: TrackingConstant.SUBACTION_VIN_ERROR,
      value: getSquishVinNoMmyPixelValue(vin, vinStylesResponse),
    },
  });
};

export const fireDecodePixel = ({ stylesLength, creativeId = APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID }) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      creative_id: creativeId,
      subaction_name: TrackingConstant.SUBACTION_STYLE_DECODE,
      value: stylesLength,
    },
  });
};

export const fireVinDecodeMatchTypePixel = (matchType, creativeId) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      creative_id: creativeId,
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      subaction_name: TrackingConstant.SUBACTION_VIN_DECODE_MATCH_TYPE,
      value: matchType ? matchType.toLowerCase() : TrackingConstant.SQUISHVIN,
    },
  });
};
