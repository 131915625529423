import { get, isEmpty } from 'lodash';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import {
  CARVANA,
  DRIVEWAY,
  JAKE_SWEENEY_EXPRESS,
  RUBYCAR,
} from 'client/site-modules/shared/constants/inventory/buy-online-dealers';
import { DELIVERY_CODES } from 'site-modules/shared/components/delivery/constants';

const AUTONATION_DEALERSHIP_NAME = 'autonation';
const HERB_CHAMBERS_DEALERSHIP_NAME = 'herb chambers';
const CARMAX_DEALERSHIP_NAME = 'carmax';
const ASBURY_DEALERSHIP_NAME = 'Asbury Automotive Group';
const AMSI_DEALERSHIP_NAME = 'AMSI';
const ECHOPARK_DEALERSHIP_NAME = 'EchoPark Automotive';

/**
 * Defines if dealer is an auto nation
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isAutonation(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName.toLowerCase() === AUTONATION_DEALERSHIP_NAME;
}

export function isAutonationMobility(vinData) {
  const AUTONATION_MOBILITY_URL_REGEXP = /https:\/\/www\.autonationmobility\.com\/.*/;
  const parentDealershipName = get(vinData, DATA_PATH.PARENT_DEALERSHIP_NAME, '');
  const listingUrl = get(vinData, 'listingUrl');
  return isAutonation(parentDealershipName) && listingUrl && AUTONATION_MOBILITY_URL_REGEXP.test(listingUrl);
}

/**
 * Defines if dealer is Herb Chambers
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isHerbChambers(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName.toLowerCase().includes(HERB_CHAMBERS_DEALERSHIP_NAME);
}

/**
 * Defines if dealer is CarMax
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isCarMax(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName.toLowerCase().includes(CARMAX_DEALERSHIP_NAME);
}

/**
 * Defines if dealer is Carvana
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isCarvana(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName.includes(CARVANA);
}

/**
 * Defines if dealer is Driveway
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isDriveway(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName.includes(DRIVEWAY);
}
/**
 * Defines if dealer is Jake Sweeney Express
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isJakeSweeneyExpress(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName.includes(JAKE_SWEENEY_EXPRESS);
}

/**
 * Defines if dealer is EchoPark
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isEchoParkAutomotive(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName === ECHOPARK_DEALERSHIP_NAME;
}

/**
 * Defines if dealer is Asbury
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isAsbury(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName === ASBURY_DEALERSHIP_NAME;
}

/**
 * Defines if dealer is AMSI_DEALERSHIP_NAME
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isAMSI(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName === AMSI_DEALERSHIP_NAME;
}

/**
 * Defines if dealer is RubyCar
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isRubycar(parentDealershipName) {
  return !!parentDealershipName && parentDealershipName.includes(RUBYCAR);
}

/**
 * Defines if dealer is TransferDealership
 * @param {string} parentDealershipName
 * @returns {boolean}
 */
export function isTransferDealership(parentDealershipName) {
  return isCarMax(parentDealershipName) || isEchoParkAutomotive(parentDealershipName);
}

/**
 * Get dealer's name if dealer is TransferDealership
 * @param {string} parentDealershipName
 * @returns {string}
 */
export function getTransferDealershipName(parentDealershipName) {
  if (isCarMax(parentDealershipName)) {
    return 'CarMax';
  }
  if (isEchoParkAutomotive(parentDealershipName)) {
    return 'EchoPark';
  }
  return '';
}

/**
 * Get dealer's valid phone number from array of phones
 * @param {Object} phoneNumbers
 * @param isDirectDealer
 * @returns {string}
 */
export function getDealerPhone({ phoneNumbers, isDirectDealer }) {
  const { trackable, ppTrackable, basicTrackable, basic } = phoneNumbers;
  let phoneData = null;

  if (isDirectDealer) {
    phoneData = isEmpty(trackable) ? ppTrackable : trackable;
  } else {
    phoneData = isEmpty(basicTrackable) ? basic : basicTrackable;
  }

  return isEmpty(phoneData) ? '' : `${phoneData.areaCode}${phoneData.prefix}${phoneData.postfix}`;
}

export function getDealerStateCode(vinData) {
  return get(vinData, 'dealerInfo.address.stateCode', '');
}

export function isCarZingPremium(partnerCode) {
  return partnerCode === 'CARZING_PREMIUM';
}

export function isCarZingPartner(vehicle) {
  return vehicle?.isTED6747Chal && isCarZingPremium(vehicle?.dealerInfo?.partnerCode);
}

export function isPartnerDealer(vinData) {
  return !!(vinData?.dealerInfo?.productFeatures?.directDealer || isCarZingPartner(vinData));
}

export function isFiveStarDealer(vinData) {
  const fiveStarYears = get(vinData, 'dealerInfo.displayInfo.fiveStarYears', []);
  return Boolean(fiveStarYears && fiveStarYears.length && isPartnerDealer(vinData));
}

export function isInstockOnline({ distance, radius, isDeliveryAvailable }) {
  return distance > radius && isDeliveryAvailable;
}

export function getMergedAddress(address) {
  return `${address.street}, ${address.city}, ${address.stateCode} ${address.zip}`;
}

export function getGoogleMapsUrl(address) {
  const baseUrl = 'https://www.google.com/maps/';

  return `${baseUrl}?q=${getMergedAddress(address)}`;
}

export function isDeliveryEligible(vehicle, userZip) {
  const deliveryCode = get(vehicle, 'deliveryOptions.deliveryCode');

  return DELIVERY_CODES.includes(deliveryCode) && !!userZip;
}
