import { CLIENT_ARTIFACT } from 'client/data/api/api-metrics';

/**
 * Gets headers for metrics
 * @param {string} actionName
 * @param {string} pageName
 * @param {string} venomVersion
 * @returns {object}
 */
export const getMetricsHeaders = (actionName, pageName, venomVersion) => ({
  'x-artifact-id': CLIENT_ARTIFACT,
  'x-artifact-version': venomVersion,
  'x-client-action-name': `${pageName}.${actionName}`,
});

/**
 * Gets headers for metrics with x-trace-id header
 * @param {string} actionName
 * @param {string} pageName
 * @param {string} venomVersion
 * @param {string} xtraceid
 * @returns {object}
 */
export const getMetricsTraceHeaders = (actionName, pageName, venomVersion, xtraceid) => ({
  'x-artifact-id': CLIENT_ARTIFACT,
  'x-artifact-version': venomVersion,
  'x-client-action-name': `${pageName}.${actionName}`,
  'x-trace-id': xtraceid,
});

/**
 * Gets headers for metrics related to page (e.g. x-edw-page-name), along with some other metrics (e.g. x-trace-id).
 * @param {string} actionName
 * @param {string} pageName
 * @param {string} pageCategory
 * @param {string} xTraceId
 * @param {string} venomVersion
 * @param {string} protocol
 * @param {string} hostName
 * @param {string} url
 * @returns {object}
 */
export const getMetricsPageHeaders = ({
  actionName,
  pageName,
  pageCategory,
  xTraceId,
  venomVersion,
  protocol,
  hostName,
  url = '',
} = {}) => {
  const result = {};

  [
    {
      headerName: 'x-edw-page-name',
      headerValue: pageName,
    },
    {
      headerName: 'x-edw-page-cat',
      headerValue: pageCategory,
    },
    {
      headerName: 'x-trace-id',
      headerValue: xTraceId,
    },
    {
      headerName: 'x-artifact-version',
      headerValue: venomVersion,
    },
    {
      headerName: 'x-artifact-id',
      headerValue: CLIENT_ARTIFACT,
    },
    {
      headerName: 'x-client-action-name',
      headerValue: !!pageName && !!actionName && `${pageName}.${actionName}`,
    },
    {
      headerName: 'x-referer',
      headerValue: !!protocol && !!hostName && `${protocol}://${hostName}${url}`,
    },
  ].forEach(({ headerName, headerValue }) => {
    if (headerValue) {
      result[headerName] = headerValue;
    }
  });

  return result;
};
