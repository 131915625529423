export const APPLE_PROVIDER_ID = 'apple.com';
export const FACEBOOK_PROVIDER_ID = 'facebook.com';
export const GOOGLE_PROVIDER_ID = 'google.com';
export const PASSWORD_PROVIDER_ID = 'password';

export const AUTH_PROVIDERS = {
  apple: {
    providerId: APPLE_PROVIDER_ID,
    provider: 'OAuthProvider',
    profileScope: ['email', 'name'],
    authAction: 'apple',
    iconName: 'apple-carplay',
    spinner: 'appleButton',
  },
  facebook: {
    providerId: FACEBOOK_PROVIDER_ID,
    provider: 'FacebookAuthProvider',
    profileScope: ['public_profile', 'email'],
    authAction: 'facebook',
    iconName: 'facebook2',
    spinner: 'facebookButton',
  },
  google: {
    providerId: GOOGLE_PROVIDER_ID,
    provider: 'GoogleAuthProvider',
    profileScope: ['profile', 'email'],
    authAction: 'googleplus',
    iconName: 'google',
    spinner: 'googleButton',
  },
  password: {
    name: 'password',
  },
};

export const PROVIDERS_MAPPING = {
  [GOOGLE_PROVIDER_ID]: 'google',
  [FACEBOOK_PROVIDER_ID]: 'facebook',
  [APPLE_PROVIDER_ID]: 'apple',
  [PASSWORD_PROVIDER_ID]: 'email',
};
