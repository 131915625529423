/**
 * This function can be called both on client and server, but on client it will do nothing.
 * On server, it will send custom metric to newrelic via new relic's node agent
 * this function was meant to be used by code in client/** folder given that /server/utils/monitoring
 * is not isomorphic thus cannot be imported by any code in client
 * @param {String} name
 * @param {Number} value
 * @param {Number} sampleRatePercent Sample rate in % - [0..100]
 */
export async function recordServerMetric(name, value, sampleRatePercent = 100) {
  if (!global.newRelicNodeAgent) {
    return;
  }
  const randomNum = Math.random(); // => number that's greater than or equal to 0 and less than 1
  if (randomNum < sampleRatePercent / 100) {
    global.newRelicNodeAgent.recordMetric(name, value);
  }
}
